import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import axios from 'axios'
// axios.defaults.withCredentials=true //让ajax携带cookie

import Qs from 'qs'
import ElementUI from 'element-ui';
import VueQuillEditor from 'vue-quill-editor'
import 'ant-design-vue/dist/antd.css';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VueSmoothScroll from "vue2-smooth-scroll";
import 'element-ui/lib/theme-chalk/index.css';
import './assets/variables.scss'; //引入修改主题文件
Vue.prototype.$ajax = axios;

Vue.prototype.Qs = Qs
//https://devms.t.educg.com'
// Vue.prototype.url= ''
Vue.prototype.url= process.env.VUE_APP_URL;
let url= process.env.VUE_APP_URL;
//线上
Vue.prototype.device = url+ '/device.do'
Vue.prototype.task = url+ '/task.do'
Vue.prototype.label = url+ '/label.do'
Vue.prototype.contact = url+ '/contact.do'
Vue.prototype.company = url+ '/company.do'
Vue.prototype.server = url+ '/server.do'
Vue.prototype.messages = url+ '/messages.do'
Vue.prototype.paas = url+ '/paas.do'

//本地
// Vue.prototype.device = url+ '/device.t'
// Vue.prototype.task = url+ '/task.t'
// Vue.prototype.label = url+ '/label.t'
// Vue.prototype.contact = url+ '/contact.t'
// Vue.prototype.company = url+ '/company.t'
// Vue.prototype.server = url+ '/server.t'
// Vue.prototype.messages = url+ '/messages.t'
// Vue.prototype.paas = url+ '/paas.t'





Vue.use(ElementUI).use(VueQuillEditor).use(VueSmoothScroll).use(Antd);;
Vue.config.productionTip = false
// 隐藏 右侧浏览器 滚动条


new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app')
