<template>
  <div>
  <quill-editor
    class="editor"
    ref="myTextEditor"

    v-model="contentGet"
    :options="editorOption"
    @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @ready="onEditorReady($event)"
    @change="onEditorChange($event)"
  >
  </quill-editor>
  <input type="file" hidden accept=".jpg,.png" ref="fileBtn" @change="handleChange" />
</div>
</template>
<script>
export default {
  props: {
    content: String,
  },
  watch: {
    content: {
      handler(val) {
        this.contentGet =val
      },
    },
  },
  data() {
    return {
      contentGet: null,
      editorOption: {
        modules: {
        
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image"], // 链接、图片、视频"video"
          ], //工具菜单栏配置
        },
        
        placeholder: "请在这里添加产品描述", //提示
        readyOnly: false, //是否只读
        theme: "snow", //主题 snow/bubble
        syntax: true, //语法检测
      },
    };
  },
  mounted() {
    this.contentGet = this.content;
     if (this.$refs.myTextEditor) {
        //myQuillEditor改成自己的
        this.$refs.myTextEditor.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    }
  },
  methods: {
    imgHandler(state) {
       if (state) {
            //触发input的单击 ，fileBtn换成自己的
           this.$refs.fileBtn.click()
       }
   },
   handleChange(e) {
    const files = Array.prototype.slice.call(e.target.files);
    console.log(files)
    if (!files) {
        return;
    }
    // let formdata = new FormData();
    // formdata.append("file_name", files[0].name);
    // formdata.append("imgs", files[0]);
    //使用了axios请求
    let FormDatas = new FormData();
              FormDatas.append("editormd-image-file", files[0]);
              // FormDatas.get("editormd-image-file");

              // FormDatas.set("editormd-image-file",file);
              // console.log('FormDatas',FormDatas)
              this.$ajax({
                method: "post",
                url: this.url+'/imageUpload.do',
                timeout: 1000,
                headers: {
                  'token': '',//接口需要的token
                  'Content-Type': 'multiart/form-data'
                },

                data: FormDatas,
                TransformReques:[data=> data]
              }).then((res) => {
                // insertFn(this.url + res.data.url, "", "");
                let selection = this.$refs.myTextEditor.quill.getSelection();
            //这里就是返回的图片地址，如果接口返回的不是可以访问的地址，要自己拼接
            let imgUrl = this.url + res.data.url; 
            imgUrl = imgUrl.replace(/\\/g,"/")   
            //获取quill的光标，插入图片 
            this.$refs.myTextEditor.quill.insertEmbed(selection != null ? selection.index : 0, 'image', imgUrl)                 
            //插入完成后，光标往后移动一位 
            this.$refs.myTextEditor.quill.setSelection(selection.index + 1);
              });
              
    // this.axios({
    //     url: this.$store.state.baseUrl + 'upload/ueditorFile',
    //     method: 'post',
    //     data: formdata,
    //     headers: {'client-identity': localStorage.getItem('session_id')}
    // }).then((res) => {
    //     //这里设置为空是为了联系上传同张图可以触发change事件
    //     this.$refs.fileBtn.value = "";
    //     if (res.data.code == 200) {
    //         let selection = this.$refs.myQuillEditor.quill.getSelection();
    //         //这里就是返回的图片地址，如果接口返回的不是可以访问的地址，要自己拼接
    //         let imgUrl = this.$store.state.baseUrl + res.data.data; 
    //         imgUrl = imgUrl.replace(/\\/g,"/")   
    //         //获取quill的光标，插入图片 
    //         this.$refs.myQuillEditor.quill.insertEmbed(selection != null ? selection.index : 0, 'image', imgUrl)                 
    //         //插入完成后，光标往后移动一位 
    //         this.$refs.myQuillEditor.quill.setSelection(selection.index + 1);
    //     } 
    // })
},
    // 失去焦点
    onEditorBlur(editor) {},
    // 获得焦点
    onEditorFocus(editor) {},
    // 开始
    onEditorReady(editor) {},
    // 值发生变化
    onEditorChange(editor) {
      console.log('editor.length',editor.html.length)
      if(editor.html.length>5000){
        this.$message.info('敏感信息：超出最大字数5000限制，当前字符长度为'+editor.html.length+',将无法保存！')
      }
      this.contentGet = editor.html;
      this.$emit("content", this.contentGet);
      console.log('editor了吗',editor);
    },
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quillEditor;
    },
  },
  // mounted() {
  //   // console.log('this is my editor',this.editor);
  // }
};
</script>
<style>
.w-e-text{
  max-height:500px;
}
.editor {
  line-height: normal !important;
  /* height: 400px; */
}
.ql-toolbar.ql-snow + .ql-container.ql-snow{
  height:300px;
  overflow: auto;
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>