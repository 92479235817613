<template>
  <div class="tab-box">
    <div class="header" v-show="qbl">
      <p class="left">
        服务中心
        <i
          class="el-icon-s-fold"
          v-if="ks"
          @click="ksFun"
          style="color: #409eff"
        ></i>
        <i
          style="color: #409eff"
          class="el-icon-s-unfold"
          v-else
          @click="ksFun"
        ></i>
      </p>
      <p class="right">
        <!--
                 <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            点我查看<i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
    
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix">
            <span @click="keySet"
              >设置KEY</span
            >
          </el-dropdown-item>
          <el-dropdown-item class="clearfix">
            <span class="userB" @click="BzFun"
              >帮助 <i class="el-icon-question"></i
            ></span>
          </el-dropdown-item>
          <el-dropdown-item class="clearfix">
            <el-badge
              :value="msgnumber"
              class="item"
              :max="99"
              style="margin-right: 20px"
              :hidden="msgnumber == 0 ? true : false"
            >
              <span @click="badgeFun"
                >消息</span
              >
            </el-badge>
          </el-dropdown-item>

       
  

  
          </el-dropdown-menu>
        </el-dropdown>
        -->
        <!-- <el-tooltip class="item" effect="dark" content="开发中" placement="top-start"> -->
  

        <el-select v-model="value" placeholder="请选择监控面板"   size="small"  @change="jkListClick" style="width:150px;margin-right:10px;">
          <el-option
          v-for="(item,index) in jkList"
              :key="index"
            
            :label="item.name"
            :value="item.url"
          >
          </el-option>
        </el-select>

        <el-button
          type="success"
          size="mini"
          plain
          @click="blanFun"
          style="margin-right: 10px"
          >异常数据监测平台</el-button
        >
        <el-badge
          :value="msgnumber"
          class="item"
          :max="99"
          style="margin-right: 20px"
          :hidden="msgnumber == 0 ? true : false"
        >
          <el-button type="warning" plain size="mini" @click="badgeFun"
            >消息</el-button
          >
        </el-badge>
        <el-dialog
          title="消息"
          :visible.sync="badgeVisible"
          width="30%"
          style="text-align: left"
        >
          <el-popconfirm :title="`确定全部删除？`" @confirm="delAll">
            <el-button
              size="mini"
              type="danger"
              plain
              slot="reference"
              style="cursor: pointer; color: red; margin-bottom: 15px"
              >全部删除</el-button
            >
          </el-popconfirm>

          <el-collapse
            v-model="activeNames"
            @change="handleChange"
            accordion
            v-if="messageAry.length > 0"
          >
            <el-collapse-item
              :name="index"
              v-for="(item, index) in messageAry"
              :key="index"
              style="color: #b5cffa"
            >
              <template slot="title">
                <!-- <el-popconfirm
                  title="这是一段内容确定删除吗？"
                >
                <i class="el-icon-delete" style="margin-right:20px;" @click="del(index)"></i>
                </el-popconfirm> -->
                <span
                  style="color: #b40101; font-size: 60px"
                  v-if="item.state == '未读'"
                  >·</span
                >
                <span
                  v-else
                  style="color: #b40101; font-size: 60px; visibility: hidden"
                >
                  ·
                </span>
                <span style="color: #72c972">【{{ item.type }}】</span>

                {{ item.title }}
              </template>
              <div>
                {{ item.body }}
                <el-popconfirm
                  :title="`确定删除：${item.title}？`"
                  @confirm="del(item._id, item.state)"
                >
                  <i
                    class="el-icon-delete"
                    slot="reference"
                    style="
                      cursor: pointer;
                      color: red;
                      width: 100%;
                      text-align: right;
                      padding-right: 10px;
                    "
                  ></i>
                </el-popconfirm>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="pageM"
            layout="total, prev, pager, next"
            :total="totalPage"
            class="pagin"
            v-if="messageAry.length > 0"
          >
          </el-pagination>
          <div v-if="messageAry.length == 0">暂无消息</div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="badgeVisible = false">关闭</el-button>
          </span>
        </el-dialog>
        <span class="userB" @click="BzFun"
          >帮助 <i class="el-icon-question"></i
        ></span>
        <!-- </el-tooltip> -->
        <!---帮助-->

        <!--el-icon-chat-line-square-->
        <el-dialog
          title="帮助"
          :visible.sync="dialogBz"
          width="30%"
          style="text-align: left"
        >
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>SSH-putty打开控制台操作说明：</span>
            </div>
            <div class="text item">
              1、下载并安装putty，下载sshlaunch.zip并解压；
            </div>
            <div class="text item">
              2、安装：把putty.exe拖到这个sshlaunch.exe上，如果uac弹出就选择同意即可
              ；
            </div>
            <div class="text item">
              3、使用puttygen.exe生成个人密钥；将公钥拷贝到平台的“设置KEY”中；
            </div>
            <div class="text item">4、打开putty中pageant加入本人私钥；</div>
            <div class="text item">5、如果需要使用vpn的请登录vpn；</div>
            <div class="text item">
              6、点击设备命令行图标即可打开并登录服务器；
            </div>
          </el-card>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogBz = false">关闭</el-button>
            <!-- <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        > -->
          </span>
        </el-dialog>

        <el-button type="primary" plain size="small" @click="keySet"
          >设置KEY</el-button
        >
        <span class="user">{{ userName }}</span>
        <el-button size="small" @click="out">退出</el-button>
      </p>
      <el-dialog title="设置KEY" :visible.sync="dialogVisible" width="30%">
        <el-input
          v-model="keyValue"
          placeholder="请设置KEY"
          type="textarea"
        ></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="keySetFun">保 存</el-button>
        </span>
      </el-dialog>
    </div>

    <el-tabs
      :tab-position="tabPosition"
      style="height: calc(100vh - 60px)"
      v-if="qbl"
      @tab-click="tabFun"
      v-model="tabName"
      class="only"
    >
      <el-tab-pane :name="item" v-for="(item, index) in tabList" :key="index">
        <span slot="label">
          <span v-if="!ks">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item"
              placement="right"
            >
              <i class="el-icon-s-platform" v-if="item == '设备管理'"></i>
              <i class="el-icon-s-grid" v-if="item == '服务管理'"></i>
              <i class="el-icon-s-home" v-if="item == '单位管理'"></i>
              <i class="el-icon-s-order" v-if="item == '任务管理'"></i>
              <i class="el-icon-s-help" v-if="item == '交换机设备管理'"></i>
              <i class="el-icon-s-opportunity" v-if="item == '咨询管理'"></i>
              <i
                class="el-icon-s-opportunity"
                v-if="item == '教学平台管理'"
              ></i>
            </el-tooltip>
          </span>
          <span v-else>
            <span v-if="item == '设备管理'"
              ><i class="el-icon-s-platform" style="margin-right: 10px"></i
              >设备管理</span
            >
            <span v-if="item == '服务管理'">
              <i class="el-icon-s-grid" style="margin-right: 10px"></i
              >服务管理</span
            >
            <span v-if="item == '单位管理'">
              <i class="el-icon-s-home" style="margin-right: 10px"></i
              >单位管理</span
            >
            <span v-if="item == '任务管理'">
              <i class="el-icon-s-order" style="margin-right: 10px"></i>
              任务管理</span
            >
            <span v-if="item == '交换机设备管理'">
              <i class="el-icon-s-help" style="margin-right: 10px"></i
              >交换机设备管理</span
            >

            <span v-if="item == '咨询管理'">
              <i class="el-icon-s-opportunity" style="margin-right: 10px"></i
              >咨询管理</span
            >
            <span v-if="item == '教学平台管理'">
              <i class="el-icon-s-help" style="margin-right: 10px"></i
              >教学平台管理</span
            >
          </span>
        </span>

        <about
          v-if="item == '设备管理'"
          @quan="quan"
          :qb="qb"
          :companyAryD="companyAry"
          @numberFun="numberFun"
        ></about>
        <give
          @quan="quan"
          :qb="qb"
          :companyAryD="giveAry"
          v-if="item == '服务管理'"
        ></give>
        <user
          @quan="quan"
          :qb="qb"
          :companyAryD="UserAry"
          v-if="item == '单位管理'"
        ></user>
        <task
          @quan="quan"
          :qb="qb"
          :companyAryD="taskAry"
          v-if="item == '任务管理'"
        ></task>
        <switchJ
          @quan="quan"
          :qb="qb"
          :companyAryD="switchAry"
          v-if="item == '交换机设备管理'"
        ></switchJ>
        <jxJ
          @quan="quan"
          :qb="qb"
          :companyAryD="jxAry"
          v-if="item == '教学平台管理'"
        ></jxJ>

        <apply
          @quan="quan"
          :qb="qb"
          :companyAryD="sqAry"
          v-if="item == '咨询管理'"
        ></apply>
      </el-tab-pane>
      <!-- <el-tab-pane name="设备管理">
        <span slot="label">
          <el-tooltip
            class="item"
            effect="dark"
            content="设备管理"
            placement="right"
          >
            <i class="el-icon-s-platform"></i>
          </el-tooltip>
        </span>

        <about
          @quan="quan"
          :qb="qb"
          :companyAryD="companyAry"
          @numberFun="numberFun"
        ></about
      ></el-tab-pane>
      

      <el-tab-pane name="服务管理">
        <span slot="label">
          <el-tooltip
            class="item"
            effect="dark"
            content="服务管理"
            placement="right"
          >
            <i class="el-icon-s-grid"></i>
          </el-tooltip>
        </span>
        <give @quan="quan" :qb="qb" :companyAryD="giveAry"></give
      ></el-tab-pane>

      <el-tab-pane name="单位管理">
        <span slot="label">
          <el-tooltip
            class="item"
            effect="dark"
            content="单位管理"
            placement="right"
          >
            <i class="el-icon-s-home"></i>
          </el-tooltip>
        </span>
        <user @quan="quan" :qb="qb" :companyAryD="UserAry"></user
      ></el-tab-pane>

      <el-tab-pane name="任务管理">
        <span slot="label">
          <el-tooltip
            class="item"
            effect="dark"
            content="任务管理"
            placement="right"
          >
            <i class="el-icon-s-order"></i>
          </el-tooltip>
        </span>
        <task @quan="quan" :qb="qb" :companyAryD="taskAry"></task
      ></el-tab-pane>
      <el-tab-pane name="交换机设备管理">
        <span slot="label">
          <el-tooltip
            class="item"
            effect="dark"
            content="交换机设备管理"
            placement="right"
          >
            <i class="el-icon-s-help"></i>
          </el-tooltip>
        </span>
        <switchJ @quan="quan" :qb="qb" :companyAryD="switchAry"></switchJ
      ></el-tab-pane> -->

      <!-- <el-tab-pane label="配置管理">配置管理</el-tab-pane>
      <el-tab-pane label="角色管理">角色管理</el-tab-pane>
      <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane> -->
    </el-tabs>
    <about
      @quan="quan"
      v-if="!qbl && tabName == '设备管理'"
      :qb="qb"
      :companyAryD="companyAry"
      @numberFun="numberFun"
    ></about>
    <give
      @quan="quan"
      v-if="!qbl && tabName == '服务管理'"
      :qb="qb"
      :companyAryD="giveAry"
    ></give>
    <user
      @quan="quan"
      v-if="!qbl && tabName == '单位管理'"
      :qb="qb"
      :companyAryD="UserAry"
    ></user>
    <task
      @quan="quan"
      v-if="!qbl && tabName == '任务管理'"
      :qb="qb"
      :companyAryD="taskAry"
    ></task>
    <apply
      @quan="quan"
      v-if="!qbl && tabName == '咨询管理'"
      :qb="qb"
      :companyAryD="sqAry"
    ></apply>
    <switchJ
      @quan="quan"
      v-if="!qbl && tabName == '交换机设备管理'"
      :qb="qb"
      :companyAryD="switchAry"
    ></switchJ>

    <jxJ
      @quan="quan"
      v-if="!qbl && tabName == '教学平台管理'"
      :qb="qb"
      :companyAryD="jxAry"
    ></jxJ>
  </div>
</template>
<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  height: 60px;
  box-shadow: 1px 1px 1px 1px #e8e8e8;
  line-height: 40px;
  background: #414040;
  border-bottom: 1px solid #8e8b8b;
  .left {
    // line-height:40px;
    padding-left: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    // color: #b9b9b9;
  }
  .right {
    .user {
      margin: 10px;
      color: rgb(233, 230, 230);
    }
    .userB {
      margin: 10px;
      color: #ff891b;
      cursor: pointer;
    }
  }
}
</style>
<style >
.el-collapse-item__header {
  color: #a1d1ff;
}
.pagin {
  width: 100%;
  text-align: right;
}
.el-button--mini {
  height: 32px;
}
.el-tabs__content {
  margin-top: -1px !important;
}
.el-card__header {
  padding: 0 20px;
}
.el-collapse-item__content {
  padding-bottom: 8px !important;
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: 1px solid #626262 !important;
}
thead tr th {
  /* background-color: #011b27 !important; */
  /* color: #b9b9b9; */
}
.el-table_.el-table__fixed,
.el-table__fixed-right {
  box-shadow: 0 0 10px rgb(215 215 215 / 15%) !important;
}
/* .ql-snow.ql-toolbar button svg, .ql-snow .ql-toolbar button svg{
  background:#ccc;

}
.ql-snow .ql-picker{
  color:#ccc
} */
.ql-editor.ql-blank::before {
  color: #ccc !important;
}
.header .el-textarea__inner {
  min-height: 200px !important;
}

.el-table__body-wrapper {
  &::-webkit-scrollbar {
    width: 0;
    background: rgba(213, 215, 220, 0.3);
    border: none;
  }
  &::-webkit-scrollbar-track {
    border: none;
  }
}

.el-table th.gutter {
  display: none;
  width: 0;
}
.el-table colgroup col[name="gutter"] {
  display: none;
  width: 0;
}

.el-table__body {
  width: 100% !important;
}

/* .el-table {
  display: flex;
  flex-direction: column;
}
.el-table__header-wrapper {
  overflow: visible !important;
} */
.el-tabs__nav-scroll {
  margin-top: 20px;
}
.el-tabs__content {
  margin-top: 17px;
  overflow: auto !important;
  height: calc(100vh - 90px);
}
.el-tabs__header.is-top {
  padding: 10px;
}
.only.el-tabs--left .el-tabs__header.is-left {
  background: #414040;
}
.only.tab-box .el-tabs--left .el-tabs__item.is-left {
  text-align: left !important;
}
.only.tab-box .el-tabs--left .el-tabs__item:hover,
.tab-box .el-tabs--left .el-tabs__item.is-active {
  color: #409eff;
  cursor: pointer;
}
.tab-box .el-tabs--left .el-tabs__item {
  color: #fff;
}
.elAdd  .el-tabs__nav .el-tabs__item{
  color:#303133;
}
.comment .comment-header-box{
  bottom:50px !important;
}
@media (max-width: 800px) {
  .card-list {
    width: 100%;
  }
}
</style>
<script>
//EduCGRunning  //服务运行状态检测点；
import about from "../components/about.vue";
import user from "../components/user.vue";
import task from "../components/task.vue";
import give from "../components/give.vue";
import switchJ from "../components/switch.vue";
import apply from "../components/apply.vue";
import jxJ from "../components/jxJ.vue";

export default {
  components: {
    about,
    user,
    task,
    give,
    switchJ,
    apply,
    jxJ,
  },
  data() {
    return {
      jkList: [],
      tabList: [],
      msgnumber: 0,
      activeNames: "",
      messageAry: [],
      badgeVisible: false,
      dialogBz: false,
      keyValue: "",
      value:'',
      dialogVisible: false,
      tabPosition: "left",
      userName: "",
      qbl: true,
      qb: "全屏视图",
      tabName: "设备管理",
      companyAry: [],
      switchAry: [],
      giveAry: [],
      taskAry: [],
      UserAry: [],
      sqAry: [],
      jxAry: [],
      currentPage1: 1,
      pageM: 10,
      totalPage: "",
      ks: true,
    };
  },
  mounted() {
    this.jkListFun();
    if (window.location.href.indexOf("wxqrcode") != -1) {
      //企业微信登录来的
      this.wxqrcodeGet();
    }
    if (window.localStorage.getItem("anjing-applist")) {
      this.tabList = JSON.parse(window.localStorage.getItem("anjing-applist"));
    }

    if (document.body.clientWidth > 800) {
      this.tabPosition = "left";
    } else {
      this.tabPosition = "top";
    }
    this.userName = window.localStorage.getItem("anjing-nameNew");
  },
  methods: {
    jkListClick(e) {
      console.log(e);
      window.open(e);
    },
    jkListFun() {
      let data = {
        op: "pluginlist",
        // _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.jkList =   response.data.data
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    wxqrcodeGet() {
      let data = {
        op: "getuser",
        // _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "new_login.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            window.localStorage.setItem(
              "anjing-applist",
              JSON.stringify(response.data.applist)
            );
            _this.tabList = response.data.applist;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    ksFun() {
      this.ks = !this.ks;
    },
    delAll() {
      let data = {
        op: "delall",
        // _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.messages, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            console.log(response.data.data);
            _this.messagesFun();

            _this.msgnumber = 0;

            // _this.activeNames = e;
            // _this.msgnumber--;
            // console.log(_this.messageAry);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    blanFun() {
      let routeUrl = this.$router.resolve({
        path: "./bigScreen", // 这里的路径就可以正常的写，不需要添加_blank: true
      });
      window.open(routeUrl.href, "_blank");
      // window.open('./bigScreen')
    },
    del(id, state) {
      let data = {
        op: "del",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.messages, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            console.log(response.data.data);
            _this.messagesFun(true);
            if (state == "未读") {
              _this.msgnumber--;
            }
            // _this.activeNames = e;
            // _this.msgnumber--;
            // console.log(_this.messageAry);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // this.messagesFun();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.activeNames = "";
      this.messagesFun();
    },
    //msgnumber
    numberFun(msgnumber) {
      console.log(msgnumber);
      this.msgnumber = msgnumber;
    },
    handleChange(e) {
      console.log(e);

      //  if(t!=''&& this.currentPage1>1){
      //   t= (this.currentPage1-1)*10+e
      //  }

      if (e.toString() != "" && this.messageAry[e].state == "未读") {
        let id = this.messageAry[e]._id;
        let data = {
          op: "read",
          _id: id,
        };
        let _this = this;
        this.$ajax
          .post(this.messages, _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              console.log(response.data.data);
              _this.messagesFun();
              _this.activeNames = e;
              _this.msgnumber--;

              console.log(_this.messageAry);
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    messagesFun(del = false) {
      console.log(del);
      let data = {
        op: "search",
        page: this.currentPage1,
        limit: 10,
      };
      let _this = this;
      this.$ajax
        .post(this.messages, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            if (del) {
              console.log(del);
              _this.activeNames = "";
            }
            console.log(response.data.data);
            _this.messageAry = response.data.data.data;
            _this.totalPage = response.data.data.recordcount;

            console.log(_this.messageAry);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    readFun(id) {
      let data = {
        op: "read",
        _id: id,
        // state:''
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.messages, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            console.log(response.data.data);
            _this.messageAry = response.data.data.data;
            console.log(_this.messageAry);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    badgeFun() {
      this.badgeVisible = true;
      this.currentPage1 = 1;
      this.messagesFun();
    },
    BzFun() {
      this.dialogBz = true;
    },
    tabFun(val) {
      this.tabName = val.name;
      if (this.tabName == "设备管理") {
        this.companyAry = ["2"];
      }
      if (this.tabName == "交换机设备管理") {
        this.switchAry = ["2"];
      }
      console.log("this.tabName", this.tabName);
      if (this.tabName == "教学平台管理") {
        this.jxAry = ["2"];
      }
      if (this.tabName == "服务管理") {
        this.giveAry = ["2"];
      }
      if (this.tabName == "单位管理") {
        this.UserAry = ["2"];
      }
      if (this.tabName == "任务管理") {
        this.taskAry = ["2"];
      }
      if (this.tabName == "咨询管理") {
        this.sqAry = ["2"];
      }

      console.log(val);
    },
    // companyFun() {
    //   let data = {
    //     op: "companylist",
    //     // title: this.input,
    //   };
    //   let _this = this;
    //   this.$ajax
    //     .post(this.url + "/device.do", _this.Qs.stringify(data))
    //     .then(function (response) {
    //       console.log(response);
    //       if (response.data.value == "ok") {
    //         console.log(response.data.data);
    //         _this.companyAry = response.data.data;
    //       } else {
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    keySet() {
      this.dialogVisible = true;
      // 调取回显接口
      let data = {
        op: "getpkey",
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.keyValue = response.data.data;
          } else {
            // _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    keySetFun() {
      if (this.keyValue.trim() == "") {
        this.$message.error("设置KEY目前为空，请输入值后，再保存！");
        return;
      }
      // 调取接口
      let data = {
        op: "setpkey",
        key: this.keyValue,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.dialogVisible = false;
          if (response.data.value == "ok") {
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    quan(val) {
      this.qbl = val;
      console.log(val);
      if (val) {
        this.qb = "全屏视图";
      } else {
        this.qb = "还原视图";
      }
    },
    out() {
      let data = {
        op: "logout",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/new_login.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$notification.open({
              message: "退出",
              duration: 2,
              description: response.data.msg,
              onClick: () => {
                console.log("登出");
              },
            });
            // window.localStorage.setItem("anjing-tokenName", "");
            // window.localStorage.setItem("anjing-nameNew", "");
            _this.loginOk = false;
            _this.$router.push({ path: "/" });
          } else {
            _this.$notification.open({
              message: "退出",
              duration: 2,
              description: response.data.msg,
              onClick: () => {
                console.log("退出");
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>