<template>
  <div class="about">
    <!-- <div class="qiehuan">
        <el-button type="warning" size="small" class="qh">切换大屏展示</el-button>
      </div> -->

    <div class="qiehuan">
      <!-- <el-button type="warning" size="small" class="qh">切换大屏展示</el-button> -->

      <el-tooltip
        class="item"
        effect="dark"
        :content="qb"
        placement="top-start"
      >
        <el-button
          type="warning"
          size="mini"
          class="add"
          icon="el-icon-monitor"
          style="margin-left: 10px"
          @click="quan"
        ></el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :content="czText"
        placement="top-start"
      >
        <el-button
          type="danger"
          size="mini"
          class="add"
          plain
          @click="czFun"
          icon="el-icon-setting"
        ></el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="添加单位"
        placement="top-start"
      >
        <el-button
          type="success"
          size="mini"
          class="add"
          icon="el-icon-circle-plus-outline"
          @click="add('add', '', '添加单位')"
        ></el-button>
      </el-tooltip>

      <p class="sou">
        <el-input
          v-model="input"
          placeholder="请输入名称"
          style="float: left; margin-right: 2px; width: 150px"
          size="small"
          @change="init(1)"
        ></el-input>

        <el-tooltip
          class="item"
          effect="dark"
          content="搜索"
          placement="top-start"
        >
          <el-button
            type="primary"
            size="mini"
            @click="init(1)"
            icon="el-icon-search"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="重置"
          placement="top-start"
        >
          <el-button
            type="danger"
            size="mini"
            @click="resFun"
            icon="el-icon-refresh-left"
          ></el-button>
        </el-tooltip>
      </p>
    </div>
    <div style="text-align: left; color: red">
      温馨提示：双击列表每行的数据可进入查看、编辑
    </div>
    <el-table
      :data="tableData"
      border
      ref="table"
      style="width: 100%"
      @row-dblclick="rowdbFun"
      v-loading="loading"
      element-loading-text="安装中"
      element-loading-spinner="el-icon-loading"
      @sort-change="sortChange"
    >
      <el-table-column
        type="index"
        :index="indexFun"
        width="50"
        label="序号"
        fixed="left"
      >
      </el-table-column>
      <el-table-column prop="title" label="名称"> </el-table-column>
      <el-table-column prop="vpnid" label="远程设备ID"> </el-table-column>
      <el-table-column prop="remote_port" label="远程设备端口">
      </el-table-column>

      <el-table-column
        prop="businessday"
        label="商业授权剩余（天）"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.businessday > 7 && scope.row.businessday <= 30"
            style="color: yellow"
            >{{ scope.row.businessday }}</span
          >
          <span v-else-if="scope.row.businessday <= 7" style="color: red">{{
            scope.row.businessday
          }}</span>
          <span v-else>{{ scope.row.businessday }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="tryday"
        label="试用授权剩余（天）"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.tryday > 7 && scope.row.tryday <= 30"
            style="color: yellow"
            >{{ scope.row.tryday }}</span
          >
          <span v-else-if="scope.row.tryday <= 7" style="color: red">{{
            scope.row.tryday
          }}</span>
          <span v-else>{{ scope.row.tryday }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="serviceday"
        label="服务剩余（天）"
        sortable="custom"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.serviceday > 7 && scope.row.serviceday <= 30"
            style="color: yellow"
            >{{ scope.row.serviceday }}</span
          >
          <span v-else-if="scope.row.serviceday <= 7" style="color: red">{{
            scope.row.serviceday
          }}</span>
          <span v-else>{{ scope.row.serviceday }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="comment" label="备注"> </el-table-column>
      <!-- <el-table-column prop="seninfo" label="敏感信息"> </el-table-column> -->
      <el-table-column fixed="right" label="操作" width="80" v-if="czL">
        <template slot-scope="scope">
          <i
            class="el-icon-edit"
            @click="add('edit', scope.row, '编辑')"
            style="cursor: pointer; color: #409eff"
          ></i>

          <el-popconfirm
            :title="`确定删除${scope.row.title}？`"
            @confirm="del(scope.row._id, scope.row.title)"
          >
            <i
              class="el-icon-delete"
              slot="reference"
              style="margin-left: 15px; cursor: pointer; color: red"
            ></i>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="currentP"
      :current-page="currentPage4"
      :page-sizes="[15, 20, 25, 30]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!---编辑/添加-->
    <el-dialog :title="title" :visible.sync="dialogFormVisible" class="userL">
      <el-form
        :model="ruleFormA"
        :rules="rulesA"
        ref="ruleFormA"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="title">
          <el-input v-model="ruleFormA.title" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="远程设备ID" prop="vpnid">
          <el-input v-model="ruleFormA.vpnid" placeholder=""></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="comment">
          <el-input
            v-model="ruleFormA.comment"
            placeholder=""
            type="textarea"
            class="addStyle"
          ></el-input>
        </el-form-item>
        <el-form-item label="附件" prop="file" style="width: 100%">
          <!-- <el-upload
            style="float: left"
            class="upload-demo"
            action
            :http-request="uploadFile"
            ref="upload"
            :limit="fileLimit"
            :on-remove="handleRemove"
            :file-list="fileList"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :show-file-list="false"
            :headers="headers"
          >
      
            <el-button class="btn"
              ><i class="el-icon-paperclip"></i>上传附件</el-button
            >
          </el-upload> -->
          <el-upload
            style="float: left"
            class="upload-demo"
            action="/"
            ref="upload"
            :on-success="onSuccess"
            multiple
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
            :on-error="onError"
            :file-list="fileList"
            :show-file-list="showFList"
            :http-request="checkedFile"
            @close="handleClose(index, tag)"
          >
            <el-button size="small" type="primary" v-if="upEdit"
              >上传附件</el-button
            >
          </el-upload>
          <el-button
            size="small"
            type="primary"
            v-if="!upEdit"
            @click="upFun"
            style="float: left"
            >上传附件</el-button
          >
          <div class="tabAll">
            <el-tag
              v-for="(tag, index) in fileList"
              :key="index"
              style="
                margin-right: 10px;
                float: left;
                margin-top: 10px;
                cursor: pointer;
              "
              :disable-transitions="false"
              @close="handleClose(index, tag)"
              closable
              @click="downloadFile(tag)"
              ><i class="el-icon-paperclip"></i
              ><span class="tagtext">{{ tag.name }}</span></el-tag
            >
            <!-- <el-tooltip
              class="item"
              effect="dark"
              :content="tag.name"
              placement="top-start"
              v-for="(tag, index) in fileList"
              :key="index"
            >
              <el-tag
                style="margin-right: 10px; float: left; margin-top: 10px"
                :disable-transitions="false"
                @close="handleClose(index, tag)"
                closable
                @click="downloadFile(tag)"
                ><i class="el-icon-paperclip"></i
                ><span class="tagtext">{{ tag.name }}</span></el-tag
              >
            </el-tooltip> -->
          </div>
        </el-form-item>
        <el-form-item label="模块授权" prop="comment">
          <el-button
            size="small"
            style="float: left"
            type="success"
            @click="SqFun"
            >授权</el-button
          >
          <el-button
            size="small"
            style="float: left"
            type="success"
            plain
            @click="licenseFun"
            >查看license</el-button
          >
          <el-drawer
            :append-to-body="true"
            title="我是标题"
            :visible.sync="drawerLIC"
            :with-header="false"
            size="60%"
          >
            <el-card class="box-card" v-if="getlsAry.length>0">
              <div
                v-for="(item, index) in getlsAry"
                :key="index"
                class="text item"
              >
                <el-descriptions title="" :column="3" border :labelStyle="{width:'100px'}" size="mini">
                  <el-descriptions-item label="验证码">{{
                    item.code
                  }}</el-descriptions-item>
                       <el-descriptions-item label="版本">{{
                    item.version
                  }}</el-descriptions-item>
              

                 
                  <el-descriptions-item label="平台">
                    {{ item.paas }}
                  </el-descriptions-item>
                  <el-descriptions-item label="使用人数">{{
                    item.number
                  }}</el-descriptions-item>
                   <el-descriptions-item label="申请人">{{
                    item._sys_creator
                  }}</el-descriptions-item>
                     <el-descriptions-item label="申请时间">{{
                    item._sys_createtime

                  }}</el-descriptions-item>
                  <el-descriptions-item label="申请原因" :span="3">{{
                    item.reason
                  }}</el-descriptions-item>
                  <!-- <el-descriptions-item label="模块时间"  :span="2">
                    {{ item.trytime }}
                  </el-descriptions-item> -->
                
                 
                  <el-descriptions-item label="license" :span="3">{{
                    item.license
                  }}</el-descriptions-item>
                
                  
                  <el-descriptions-item label="模块">
                    <el-table
                      :data="item.try"
                      height="400"
                      border
                      style="width: 60%"
                       size="mini"
                    >
                      <el-table-column prop="moduleName" label="模块名称" >
                      </el-table-column>
                      <el-table-column prop="trialDuration" label="时长（月）" >
                      </el-table-column>
                     
                    </el-table>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </el-card>
            <el-empty description="暂无数据" v-else></el-empty>
          </el-drawer>
        </el-form-item>

        <el-form-item
          label="敏感信息"
          prop="seninfo"
          v-if="title == '添加单位'"
          style="width: 100%"
          class="min"
        >
          <!-- <el-input
            v-model="ruleFormA.seninfo"
            placeholder="请输入敏感信息"
            type="textarea"
            class="addStyle"
          ></el-input> -->
          <!-- <Editormd
            :content="ruleFormA.seninfo"
            @content="contentFun"
          ></Editormd> -->

          <wangEditor
            style="text-align: left"
            :content="ruleFormA.seninfo"
            @changeData="contentFun"
            :dialogFormVisible="dialogFormVisible"
            
          ></wangEditor>
        </el-form-item>
        <el-form-item
          label="敏感信息"
          v-if="title == '编辑'"
          style="width: 100%"
          class="min addAbout"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                style="float: left"
                @click="look"
                size="small"
                type="warning"
                v-show="isLook && !yz"
                >查看</el-button
              >
              <el-dialog
                :append-to-body="true"
                title="提示"
                :visible.sync="lookValue"
                width="30%"
              >
                <span>需注册手机号接收验证码，并输入验证正确。是否查看？</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="lookValue = false">取 消</el-button>
                  <el-button type="primary" @click="lookFun">查 看</el-button>
                </span>
              </el-dialog>
              <el-input
                v-show="!isLook && !yz"
                v-model="dx"
                style="width: 160px; float: left"
                size="small"
                placeholder="请输入短信验证码"
                class="addStyle"
              ></el-input>
              <el-button
                style="float: left; margin-top: 3px; margin-left: 10px"
                @click="yanz"
                size="small"
                type="warning"
                v-show="!isLook && !yz"
                >验证</el-button
              >
              <!-- <el-input
           
                v-model="ruleFormA.seninfo"
                type="textarea"
                class="addStyle"
              ></el-input> -->
              <!-- <Editormd
                :content="ruleFormA.seninfo"
                @content="contentFun"
                v-if="yz"
              ></Editormd> -->
              <!-- <wangEditor
                style="text-align: left"
                v-model="ruleFormA.seninfo"
                :isClear="isClear"
                @change="contentFun"
                v-if="yz"
              ></wangEditor> -->
              <wangEditor
                style="text-align: left"
                :content="ruleFormA.seninfo"
                @changeData="contentFun"
                :dialogFormVisible="dialogFormVisible"
                v-if="yz"
              ></wangEditor>
              <el-button v-if="yz" type="danger" size="small" @click="hisFun()"
                >历史版本浏览</el-button
              >
              <el-drawer
                title="敏感信息历史版本记录"
                :visible.sync="tableVis"
                direction="rtl"
                size="50%"
                :append-to-body="true"
              >
                <el-table :data="seninfo_log" border>
                  <el-table-column
                    property="date"
                    label="日期"
                    width="250"
                  ></el-table-column>
                  <el-table-column property="info" label="敏感信息">
                    <template slot-scope="scope">
                      <el-popover placement="left" width="700" trigger="hover">
                        <Editormd :content="scope.row.info"></Editormd>
                        <!-- <el-table :data="gridData">
    <el-table-column width="150" property="date" label="日期"></el-table-column>
    <el-table-column width="100" property="name" label="姓名"></el-table-column>
    <el-table-column width="300" property="address" label="地址"></el-table-column>
  </el-table> -->
                        <span slot="reference">预览信息</span>
                      </el-popover>
                    </template>
                  </el-table-column>
                  <el-table-column property="info" label="操作">
                    <template slot-scope="scope">
                      <el-button
                        @click="huiFun(scope.row)"
                        size="mini"
                        type="success"
                        >恢复</el-button
                      >
                    </template>
                  </el-table-column>
                  <!-- <el-table-column property="address" label="地址"></el-table-column> -->
                </el-table>
              </el-drawer>
            </div>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dataSet(title)">确 定</el-button>
      </div>
    </el-dialog>
    <!----模块授权-->
    <el-dialog
      title="模块授权"
      append-to-body
      :visible.sync="dialogMK"
      width="70%"
      style="text-align: left"
      :show-close="false"
    >
      <div
        style="
          margin-bottom: 10px;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
        "
      >
        <span
          ><span style="font-size: 20px; font-weight: bold; color: #f0c476"
            >商业
          </span>
          授权到期时间：
          <el-date-picker
            size="small"
            v-model="syValue"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker></span
        ><span
          ><span style="font-size: 20px; font-weight: bold; color: #f0c476"
            >试用
          </span>
          授权到期时间：
          <el-date-picker
            size="small"
            v-model="shValue"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker
        ></span>
        <span
          ><span style="font-size: 20px; font-weight: bold; color: #f0c476"
            >服务
          </span>
          到期时间：
          <el-date-picker
            size="small"
            v-model="fwValue"
            value-format="yyyy-MM-dd hh:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker
        ></span>
      </div>
      <el-table :data="MkData" height="400" border style="width: 100%">
        <el-table-column prop="title" label="模块"> </el-table-column>
        <el-table-column prop="sq" label="授权">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.sq"
              placeholder="请选择"
              @change="mkSlectFun"
              style="width: 50%"
            >
              <el-option label="无" value="无"> </el-option>
              <el-option label="商业" value="商业"> </el-option>
              <el-option label="试用" value="试用"> </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时长（月）">
          <template slot-scope="scope">
            <!-- <el-select
              v-model="scope.row.time"
              placeholder="请选择"
              @change="mkSlectFun"
              style="width: 50%"
            > -->
            <!-- <el-option :label="index" :value="index" v-for="(item,index) in 1000" :key="index"> </el-option> -->
            <!-- <el-option label="好的3" value="好的3" > </el-option>
              <el-option label="好的1" value="好的1"> </el-option>
              <el-option label="好的2" value="好的2" ></el-option>  -->

            <!-- <el-option label="无" value="无"> </el-option>
              <el-option label="商业" value="商业"> </el-option>
              <el-option label="试用" value="试用"> </el-option>
            </el-select> -->

            <!-- <el-input-number style="width:100%" v-model="scope.row.time" :step-strictly="true" @change="uesrNumFun" :min="0" :step="1" :max="1000000" label="请输入使用人数"></el-input-number> -->
            <!-- <el-input v-model="scope.row.time" :key='scope.row._id' placeholder="请输入"  @change="mkSlectFun" :min="0" type='number' :max="1000"></el-input> -->
            <el-input-number
              style="width: 60%"
              v-model="scope.row.time"
              :key="scope.row._id"
              :step-strictly="true"
              @change="mkSlectFun($event)"
              :min="0"
              :step="1"
              :max="1000"
            ></el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="display: flex; margin: 10px 0; justify-content: space-between"
      >
        <div style="display: flex; width: 49%">
          <span style="width: 120px; line-height: 40px"
            ><span style="color: red; font-size: 14px; margin-right: 5px"
              >*</span
            >验证码：</span
          >
          <el-input
            v-model="code"
            placeholder="请输入验证码"
            style="margin-right: 10px"
          ></el-input>
        </div>
        <div style="display: flex">
          <span style="width: 120px; line-height: 40px"
            ><span style="color: red; font-size: 14px; margin-right: 5px"
              >*</span
            >选择平台：</span
          >
          <el-select
          filterable
            v-model="paaslist"
            placeholder="请选择平台"
            @change="paaslistFun"
          >
            <el-option
              :label="item.schooName"
              :value="item.code"
              v-for="(item, index) in paaslistAry"
              :key="index"
            >
              <span style="float: left">{{ item.schooName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.collegeName
              }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        style="display: flex; margin: 10px 0; justify-content: space-between"
      >
        <div style="display: flex">
          <span style="width: 120px; line-height: 40px"
            ><span style="color: red; font-size: 14px; margin-right: 5px"
              >*</span
            >使用人数：</span
          >
          <!-- <el-select
            v-model="uesrNum"
            placeholder="请选择使用人数"
            @change="uesrNumFun"
          >
            <el-option
              :label="index"
              :value="index"
              v-for="(item, index) in 10"
              :key="index"
            >
            </el-option>
          </el-select> -->
          <el-input-number
            style="width: 100%"
            v-model="uesrNum"
            :step-strictly="true"
            @change="uesrNumFun"
            :min="1"
            :step="1"
            :max="1000000"
            label="请输入使用人数"
          ></el-input-number>
        </div>

        <div style="display: flex">
          <span style="width: 120px; line-height: 40px"
            ><span style="color: red; font-size: 14px; margin-right: 5px"
              >*</span
            >版本：</span
          >
          <el-select
            v-model="version"
            placeholder="请选择版本"
            @change="versionFun"
          >
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, index) in versionAry"
              :key="index"
            >
              <!-- <span style="float: left">{{ item.schooName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.collegeName
              }}</span> -->
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="display: flex;">
        <span style="width: 100px; line-height: 40px"
          ><span style="color: red; font-size: 14px; margin-right: 5px">*</span
          >申请原因：</span
        >
        <el-input
          type="textarea"
          placeholder="请输入申请原因"
          v-model="reason"
          maxlength="100"
          show-word-limit
        >
        </el-input>
      </div>
      <div style="display: flex;margin-top:10px;">
        <div style="line-height: 40px;color:red;"
          >
          <!-- <span style="color: red; font-size: 14px; margin-right: 5px">*</span
          > -->
          【 说明 】：生成license的模块选择中“平台”必须设置时长。其余的模块时长少于“平台”时长，就是试用模块。</div
        >
   
        <!-- <el-input
          type="textarea"
          placeholder="请输入说明"
          v-model="shuoming"
          maxlength="100"
          show-word-limit
        >
        </el-input> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="lsFun" type="success" v-loading="loadingLs">申请LS</el-button>
        <el-button @click="dialogMK = false">取 消</el-button>
        <el-button type="primary" @click="MKFunS">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  <script>
import inputIp from "../components/inputIp.vue";
import Editormd from "./edmitormd.vue";
import wangEditor from "./wangEditor";
//   // 引入markdown文件示例
// import demo from '../assets/demo.md'
export default {
  components: {
    inputIp,
    Editormd,
    wangEditor,
  },
  data() {
    return {
      version: "",

      versionAry: [
        { code: 0, name: "试用版" },
        { code: 1, name: "演示版" },
        { code: 2, name: "入门版" },
        { code: 3, name: "标准版" },
        { code: 4, name: "高级版" },
        { code: 5, name: "旗舰版" },
        { code: 6, name: "开发版" },
        { code: 7, name: "在线版" },
        { code: 8, name: "企业版" },
        { code: 9, name: "科研版" },
        { code: 10, name: "过期版" },
        { code: 11, name: "基础版" },
        { code: 12, name: "学知桥版" },
      ],
      uesrNum: "",
      drawerLIC: false,
      code: "",
      reason: "",
      showFList: false,
      upEdit: false,
      syValue: "",
      shValue: "",
      fwValue: "",
      MkData: [],
      paaslist: "",
      paaslistAry: [],
      hisId: "",
      dialogMK: false,
      czText: "显示操作栏",
      czL: false,
      maxHeight: document.body.offsetHeight - 190,
      //上传后的文件列表
      fileList: [],
      // 允许的文件类型
      fileType: [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "txt",
        "png",
        "jpg",
        "bmp",
        "jpeg",
        "html",
        "zip",
      ],
      // 运行上传文件大小，单位 M
      fileSize: 50,
      // 附件数量限制
      fileLimit: 5,
      //请求头
      headers: { "Content-Type": "multipart/form-data" },
      isClear: false, //设置为true的时候，这个可以用this.wangEditorDetail=''来替代
      wangEditorDetail: "",
      upData: [],
      content: "",
      currentTitle: "",
      // currentHostname:'',
      lookValue: false,
      loading: false,
      loadingLs:false,
      limitVal: 15,
      companyV: "",
      locationV: "",
      dx: "",
      seninfo_log: [],
      // qb:"全屏视图",
      isLook: true,
      idC: "",

      hostdevD: true,
      currentPage4: 1,
      valueS: "title",
      options: [
        { name: "设备名称", id: "title" },
        { name: "IP地址", id: "ip" },
        { name: "主机名", id: "hostname" },
        { name: "服务学校", id: "school" },
      ],
      // company: [],
      locationAry: [],
      dialogFormVisible: false,
      input: "",
      id: "",
      dataSource: [],
      dataSourceHost: [],
      tableData: [],
      widthS: document.body.clientWidth,
      // addStyle:'',
      ruleFormA: {
        vpnid: "",
        title: "",
        comment: "",
        seninfo: "",
      },
      total: null,
      showpsw: null,
      tableVis: false,
      rulesA: {
        title: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
      },
      title: "编辑",
      fzAry: [],
      addType: "",
      hostdevAry: [],
      companyAry: [],
      // isLook: true,

      yz: false,
      //上传后的文件列表
      fileList: [],
      // 允许的文件类型
      fileType: [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "txt",
        "png",
        "jpg",
        "bmp",
        "jpeg",
      ],
      // 运行上传文件大小，单位 M
      fileSize: 50,
      // 附件数量限制
      fileLimit: 5,
      //请求头
      headers: { "Content-Type": "multipart/form-data" },
      sort_k: "",
      sort_v: "",
      dynamicTags: [],
      multiUploadSize: 5000000, // 大于这个大小的文件使用分块上传(后端可以支持断点续传)
      requestCancelQueue: [], // 请求方法队列（调用取消上传）
      eachSize: 5000000, // 每块文件大小
      currentChunk: 0,
      getlsAry: [],
      // gridData: [{
      //   date: '2016-05-02',
      //   name: '王小虎',
      //   address: '上海市普陀区金沙江路 1518 弄'
      // }, {
      //   date: '2016-05-04',
      //   name: '王小虎',
      //   address: '上海市普陀区金沙江路 1518 弄'
      // }, {
      //   date: '2016-05-01',
      //   name: '王小虎',
      //   address: '上海市普陀区金沙江路 1518 弄'
      // }, {
      //   date: '2016-05-03',
      //   name: '王小虎',
      //   address: '上海市普陀区金沙江路 1518 弄'
      // }],
    };
  },
  props: {
    qb: String,
    companyAryD: [],
  },

  watch: {
    companyAryD: {
      handler(val) {
        console.log(val);

        this.init(1, this.limitVal);
      },
    },
  },

  mounted() {
    // this.get_content()
    //   setInterval(()=>{
    //     this.init(this.currentPage4,this.limitVal);
    //   },60000)
    this.init(1, this.limitVal);
    this.MkFun();
    this.paaslistGetData();
    console.log(this.widthS);
    //   this.hostdevFun();
    //   this.locationFun();
    //   this.companyFun();
    //   this.fzInit();
  },
  methods: {
    versionFun(e) {
      this.version = e;
    },
    uesrNumFun(e) {
      this.uesrNum = e;
    },
    paaslistFun(e) {
      console.log(e);
      this.paaslist = e;
    },
    paaslistGetData() {
      let data = {
        op: "paaslist",
      };
      let _this = this;
      this.$ajax
        .post(this.company, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.paaslistAry = response.data.data;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });

            console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    lsFun() {
      console.log(this.MkData)
      // if(this.MkData[5].sq=='无'){
      //   this.$message.info("请选择表格中【模块名称为平台】的授权！");
      //   return;
      // }
      // if(this.MkData[5].time=='0'|| this.MkData[5].time===0){
      //   this.$message.info("表格中【模块名称为平台】的时长（月）要大于0！");
      //   return;
      // }
      for(let i=0;i<this.MkData.length;i++){
        if(this.MkData[i].title =='平台'){
          if(this.MkData[i].sq=='无'){
            this.$message.info("请选择表格中【模块名称为平台】的授权！");
            return;
          };
          if(this.MkData[i].time=='0'|| this.MkData[i].time===0){
        this.$message.info("表格中【模块名称为平台】的时长（月）要大于0！");
        return;
      }
        }
      }
      if (this.code == "") {
        this.$message.info("请输入验证码！");
        return;
      }
      if (this.paaslist == "") {
        this.$message.info("请选择平台！");
        return;
      }
      console.log('this.uesrNum',this.uesrNum)
      if (this.uesrNum === undefined) {
        this.$message.info("请选择使用人数！");
        return;
      }
      if (this.version === "") {
        this.$message.info("请选择版本！");
        return;
      }
      if (this.reason == "") {
        this.$message.info("请输入申请原因！");
        return;
      }
      
      this.loadingLs =true;
      let data = {
        op: "createls",
        _id: this.id,
        code: this.code,
        reason: this.reason,
        modulardata: this.MkData,
        trytime: this.shValue,

        businesstime: this.syValue,
        servicetime: this.fwValue,
        paas: this.paaslist,
        number: this.uesrNum,
        version: this.version,

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.company, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.loadingLs = false;
            // console.log(response.data.data);
            _this.$alert(`<p>可以到【 查看license 】列表去查看详情</p><p style='word-break: break-all;'>【 license 】:${response.data.data}</>`, '申请LS'+response.data.msg, {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true
        });
          } else {
            _this.loadingLs = false;
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });

            console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          _this.loadingLs = false;
          console.log(error);
        });
    },
    licenseFun() {
      let data = {
        op: "getls",
        _id: this.id,

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.company, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.drawerLIC = true;
            _this.getlsAry = response.data.data;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });

            console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 排序
    sortChange(e) {
      console.log(e);

      if (e.prop == "businessday") {
        this.sort_k = "businesstime";
      } else if (e.prop == "tryday") {
        this.sort_k = "trytime";
      } else {
        this.sort_k = "servicetime";
      }
      e.order == "ascending" ? (this.sort_v = 1) : (this.sort_v = -1);
      this.init(this.currentPage4, this.limitVal);
    },
    // 模块对应的授权选择
    mkSlectFun() {
      this.$forceUpdate();
      // this.$forceUpdate();

      // this.MkData.splice(e, 1, e);

      console.log(this.MkData);
      // this.MkDataJSON.parse(JSON.stringify())
    },

    //获取模块 edData查看编辑的时候带上之前编辑的数据
    MkFun(edData) {
      let data = {
        op: "courselist",

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.company, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // console.log(response.data.data);

            if (edData) {
              for (let y = 0; y < edData.length; y++) {
                // this.MkData = data.modulardata;
                for (let i = 0; i < _this.MkData.length; i++) {
                  if (_this.MkData[i].title == edData[y].title) {
                    _this.MkData[i].sq = edData[y].sq;
                    _this.MkData[i].time = edData[y].time;
                  }
                }
              }
            } else {
              _this.MkData = response.data.data;
            }
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            // _this.isLook = true;
            _this.yz = false;
            console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 模块授权
    SqFun() {
      this.dialogMK = true;
      this.code = "";
      this.reason = "";
      this.paaslist = "";
      this.uesrNum = "";
      this.version = "";
    },
    MKFunS() {
      this.dataSet();
      // 授权数据
    },
    // 下载
    downloadFile(tag) {
      if (tag.url === undefined) {
        this.$message("请稍后再试！");
        return;
      }
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = tag.url;
      document.body.appendChild(link);
      link.click();
    },
    //上传文件之前
    beforeUpload(file) {
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 50; //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$message("上传文件大小不能超过 50MB!");
          return false;
        }
        //如果文件类型不在允许上传的范围内
        // if (this.fileType.includes(FileExt)) {
        //   return true;
        // } else {
        //   this.$message.error("上传文件格式不正确!");
        //   return false;
        // }
      }
    },
    upFun() {
      this.$message("新建暂不支持上传！");
    },
    async checkedFile(options) {
      // if(!this.upEdit){
      //   this.$message("新建暂不支持上传！");
      //   return;

      // }
      debugger;
      console.log(options);
      const { multiUploadSize, getSize, splitUpload, singleUpload } = this;
      const { file, onProgress, onSuccess, onError } = options;
      // if (file.size > maxSize) {
      //     return this.$message({
      //         message: `您选择的文件大于${getSize(maxSize)}`,
      //         type: 'error'
      //     })
      // }
      // if (
      //   !(
      //     file.name.indexOf("actor") == 0 ||
      //     file.name.indexOf("activity") == 0 ||
      //     file.name.indexOf("statement") == 0
      //   )
      // ) {
      //   // if( this.fileList.length>0){
      //   //     this.fileList.shift()
      //   // }
      //   return this.$message({
      //     message: `请您使用教学平台导出的zip文件，请按原文件名称上传，不要修改文件名称`,
      //     type: "error",
      //   });
      // }
      const uploadFunc =
        file.size > multiUploadSize ? splitUpload : singleUpload;
      console.log(uploadFunc);
      try {
        await uploadFunc(file, onProgress);
        // this.$message({
        //     message: '上传成功',
        //     type: 'success'
        // })
        onSuccess();
      } catch (e) {
        console.error(e);
        this.$message({
          message: e.message,
          type: "error",
        });
        onError();
      }
      const prom = new Promise((resolve, reject) => {});
      prom.abort = () => {};
      return prom;
    },
    postFile(param, onProgress) {
      let _this = this;
      const formData = new FormData();
      for (let p in param) {
        formData.append(p, param[p]);
      }
      const { requestCancelQueue } = this;
      const config = {
        cancelToken: new this.$ajax.CancelToken(function executor(cancel) {
          if (requestCancelQueue[param.uid]) {
            requestCancelQueue[param.uid]();
            delete requestCancelQueue[param.uid];
          }
          requestCancelQueue[param.uid] = cancel;
          console.log(requestCancelQueue);
        }),
        onUploadProgress: (e) => {
          if (param.chunked) {
            e.percent = Number(
              (
                ((param.blob_num * (param.total_blob_num - 1) + e.loaded) /
                  param.eachSize) *
                100
              ).toFixed(2)
            );
          } else {
            e.percent = Number(((e.loaded / e.total) * 100).toFixed(2));
          }
          onProgress(e);
        },
      };

      return this.$ajax.post(this.company, formData, config).then((rs) => {
        // rs.data
        console.log(rs.data);
        // _this.fileList = rs.data.data.data;
        if (rs.data && rs.data.msg && rs.data.msg.indexOf("部分") != -1) {
          _this.currentChunk = rs.data;
          _this.$message.success(rs.data.msg);

          console.log(_this.fileList.length);
          _this.showFList = true;
          console.log("0000000000000000");
          console.log(document.getElementsByClassName("el-upload-list__item"));
          if (
            document.getElementsByClassName("el-upload-list__item").length != 0
          ) {
            debugger;
            document.getElementsByClassName("el-upload-list")[0].style.display =
              "block";

            for (let i = 0; i < _this.fileList.length; i++) {
              document.getElementsByClassName("el-upload-list__item")[
                i
              ].style.display = "none";
              console.log(
                document.getElementsByClassName("el-upload-list__item")[i]
              );
            }
          }
        } else {
          _this.fileList = rs.data.data.data;
          this.showFList = false;
        }
      });
    },
    removeFile(file) {
      console.log(this.requestCancelQueue);
      console.log(file);
      this.requestCancelQueue[file.uid]();
      delete this.requestCancelQueue[file.uid];
      return true;
    },
    // 格式化文件大小显示文字
    getSize(size) {
      return size > 1024
        ? size / 1024 > 1024
          ? size / (1024 * 1024) > 1024
            ? (size / (1024 * 1024 * 1024)).toFixed(2) + "GB"
            : (size / (1024 * 1024)).toFixed(2) + "MB"
          : (size / 1024).toFixed(2) + "KB"
        : size.toFixed(2) + "B";
    },
    // 单文件直接上传
    singleUpload(file, onProgress) {
      return this.postFile(
        // FormDatas.append("file", item.file);
        // FormDatas.append("op", "uploadfile");
        // FormDatas.append("_id", this.id);
        {
          op: "uploadfile",
          file,
          blob_num: 1,
          file_name: file.name,
          total_blob_num: 1,
          _id: this.id,
          // homepage: sessionStorage.getItem("homepage"),
        },
        onProgress
      );
    },
    // 大文件分块上传
    splitUpload(file, onProgress) {
      debugger;
      return new Promise(async (resolve, reject) => {
        try {
          const { eachSize } = this;
          const chunks = Math.ceil(file.size / eachSize);
          const fileChunks = await this.splitFile(file, eachSize, chunks);
          console.log(fileChunks);
          this.currentChunk = 0;
          for (let i = 0; i < fileChunks.length; i++) {
            // 服务端检测已经上传到第currentChunk块了，那就直接跳到第currentChunk块，实现断点续传
            console.log(this.currentChunk, i);

            if (
              Number(this.currentChunk) === i ||
              Number(this.currentChunk.index) === i
            ) {
              // if (Number(currentChunk.index) >= 1) {
              //     this.$message({
              //         message: currentChunk.msg,
              //         type: 'success'
              //     })
              // }
              // 每块上传完后则返回需要提交的下一块的index
              await this.postFile(
                {
                  op: "uploadfile",
                  chunked: true,
                  blob_num: i + 1,
                  total_blob_num: chunks,
                  _id: this.id,
                  eachSize,
                  file_name: file.name,
                  // fullSize: file.size,
                  // blob_num: file.uid,
                  file: fileChunks[i],
                  // homepage: sessionStorage.getItem("homepage"),
                },
                onProgress
              );
              console.log(this.currentChunk);
            }
          }
          // const isValidate = await this.validateFile({
          //     total_blob_num: fileChunks.length,
          //     file_name: file.name,
          //     // fullSize: file.size,
          //     uid: file.uid

          //     //total_blob_num  文件块总数
          //     // blob_num  当前块编号
          //     // file_name 文件名
          // })
          // if (!isValidate) {
          //     throw new Error('文件校验异常')
          // }
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },
    // 文件分块,利用Array.prototype.slice方法
    splitFile(file, eachSize, chunks) {
      debugger;
      return new Promise((resolve, reject) => {
        try {
          setTimeout(() => {
            const fileChunk = [];
            for (let chunk = 0; chunks > 0; chunks--) {
              fileChunk.push(file.slice(chunk, chunk + eachSize));
              chunk += eachSize;
            }
            resolve(fileChunk);
          }, 0);
        } catch (e) {
          console.error(e);
          reject(new Error("文件切块发生错误"));
        }
      });
    },
    onSuccess(response, file, fileList) {
      this.fileList = fileList;
      console.log(fileList);
      console.log(file);
      console.log(response);

      this.requestCancelQueue = this.fileList;
      console.log(this.requestCancelQueue);

      if (
        !(
          file.name.indexOf("actor") == 0 ||
          file.name.indexOf("activity") == 0 ||
          file.name.indexOf("statement") == 0
        )
      ) {
        this.fileList.pop();
      } else if (fileList.length == 4) {
        this.fileList.shift();
      }

      console.log(response, file, fileList);
    },
    onError(response, file, fileList) {
      console.log(response);
      console.log(file);
      console.log(fileList);
      this.$message.warning(`上传失败，请重新上传！！！`);
    },
    //上传了的文件给移除的事件，由于我没有用到默认的展示，所以没有用到
    handleRemove() {},
    //这是我自定义的移除事件
    handleClose(i, tag) {
      let _this = this;
      this.$ajax({
        method: "post",
        url: _this.company,
        headers: _this.headers,
        timeout: 30000,
        data: { op: "delfile", _id: _this.id, fid: tag.fid },
      }).then((res) => {
        if (res.data.value == "ok") {
          _this.fileList.splice(i, 1); //删除上传的文件
        } else {
          _this.$message.error("文件删除失败！");
        }
      });
      // if (this.fileList.length == 0) {
      //如果删完了
      // this.fileflag = true; //显示url必填的标识
      // this.$set(this.rules.url, 0, {
      //   required: true,
      //   validator: this.validatorUrl,
      //   trigger: "blur",
      // }); //然后动态的添加本地方法的校验规则
      // }
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    //上传文件的事件
    uploadFile(item) {
      this.$message("文件上传中........");
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData();
      FormDatas.append("file", item.file);
      FormDatas.append("op", "uploadfile");
      FormDatas.append("_id", this.id);
      let _this = this;
      this.$ajax({
        method: "post",
        url: _this.company,
        headers: _this.headers,
        timeout: 30000,
        data: FormDatas,
      }).then((res) => {
        debugger;
        if (res.data.value == "ok") {
          _this.fileList = res.data.data; //成功过后手动将文件添加到展示列表里
          // console.log(_this.fileList)
          // let i = _this.fileList.indexOf(item.file);
          // _this.fileList[i].fid = res.data.data[i].fid; //id也添加进去，最后整个大表单提交的时候需要的
          if (_this.fileList.length > 0) {
            //如果上传了附件就把校验规则给干掉
            // this.fileflag = false;
            // this.$set(this.rules.url, 0, "");
          }
          //this.handleSuccess();
        } else {
          _this.$message.error("文件上传失败！");
        }
      });
    },
    //上传成功后的回调
    handleSuccess() {},

    // 提交验证码
    yanz() {
      // console.log(id)
      // 调取接口
      // 校验通过
      if (this.dx.trim() == "") {
        this.$message({
          message: "请输入验证码！",
          type: "warning",
        });
        return;
      }
      let data = {
        op: "getseninfo",
        _id: this.id,
        code: this.dx,

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.company, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.ruleFormA.seninfo = response.data.data;

            _this.isLook = false;
            _this.yz = true;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            // _this.isLook = true;
            _this.yz = false;
            console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log(this.yz);
    },
    rowdbFun(row) {
      this.add("edit", row, "编辑");
    },
    lookFun() {
      // 校验通过
      let data = {
        op: "getvfycode",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.lookValue = false;
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
            _this.isLook = false;
          } else if (response.data.value == "fail") {
            _this.isLook = false;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            _this.isLook = true;
            // console.log( _this.isLook)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    contentFun(val) {
      this.ruleFormA.seninfo = val;
    },
    indexFun(index) {
      return this.limitVal * (this.currentPage4 - 1) + index + 1;
    },
    // 历史版本
    hisFun() {
      //this.seninfo_log
      this.tableVis = true;
    },
    huiFun(obj) {
      console.log(obj);
      let data = {
        op: "resetinfo",
        _id: this.id,
        date: obj.date,
      };
      let _this = this;
      this.$ajax
        .post(this.company, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.tableVis = false;
            _this.$message.info("恢复成功");
            _this.ruleFormA.seninfo = obj.info;
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    change() {
      console.log(7);
      this.$forceUpdate();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limitVal = val;
      this.init(1, val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    companyFunI() {
      this.init(1, this.limitVal);
    },
    locationFunI() {
      this.init(1, this.limitVal);
    },
    resFun() {
      this.input = "";
      // this.stateTop = undefined
      // this.mycreate = false;
      this.currentPage4 = 1;
      this.limitVal = 15;
      this.init(this.currentPage4, this.limitVal);
    },
    init(page, limit = this.limitVal) {
      this.currentPage4 = page;
      let data = {
        op: "search",
        title: this.input,
        //   type: this.valueS,
        page: page,
        limit: limit,
        sort_k: this.sort_k,
        sort_v: this.sort_v,
        //   location: this.locationV,
        //   company: this.companyV,
      };
      let _this = this;
      this.$ajax
        .post(this.company, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.total = response.data.data.recordcount;
            _this.tableData = response.data.data.data;
            if (_this.currentPage4 != 1 && _this.tableData.length == 0) {
              // 解决删除最后一页的只有一条数据的情况，那就从上一页开始查询
              _this.currentPage4--;
              _this.init(_this.currentPage4, _this.limitVal);
            }
            // _this.fzInit(response.data.data.data);
          } else if (response.data.value == "sessionerror") {
            // 放掉注释
            // _this.$message.error(response.data.msg);
            // setTimeout(() => {
            //   _this.$router.push({ path: "/" });
            // }, 2500);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    czFun() {
      if (this.czText == "显示操作栏") {
        this.czText = "关闭操作栏";
        this.czL = true;
        //在执行完跳转页码的数据请求后
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0;
        });
      } else {
        this.czText = "显示操作栏";
        this.czL = false;
      }
    },
    handleClick(row) {
      console.log(row);
    },
    uploadBpmn(param) {
      console.log(param.file);
      // console.log("接口")
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
    // beforeUpload(file, fileList) {
    //   console.log(0);
    //   if (file.size / (1024 * 1024) > 500) {
    //     // 限制文件大小
    //     this.$message.warning(`当前限制文件大小不能大于500M`);
    //     return false;
    //   }

    //   let suffix = this.getFileType(file.name); //获取文件后缀名
    //   let suffixArray = ["jpg", "png", "jpeg", "gif"]; //限制的文件类型，根据情况自己定义
    //   if (suffixArray.indexOf(suffix) === -1) {
    //     this.$message({
    //       message: "文件格式错误",
    //       type: "error",
    //       duration: 2000,
    //     });
    //   }
    //   return suffixArray;
    // },
    onDelete(index, rows) {
      rows.splice(index, 1);
      // this.dis = false;
    },
    //   changeFun(id) {
    //     console.log(id);
    //     this.valueS = id;
    //   },
    look() {
      debugger;
      let data = {
        op: "checkseninfo",
        // title: this.input,
        _id: this.id,
      };
      let _this = this;
      this.$ajax
        .post(this.company, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          debugger;
          _this.lookValue = false;
          if (response.data.value == "ok") {
            _this.isLook = false;
            debugger;
            _this.yz = true;
            _this.ruleFormA.seninfo = response.data.data;
          } else {
            debugger;
            _this.lookValue = true;
          }
        })
        .catch(function (error) {
          debugger;
          console.log(error);
        });
      // if (seninfo === false) {
      //   this.lookValue = true;
      // } else {
      //   this.ruleFormA.seninfo = seninfo;
      //   this.isLook = false;
      //   this.yz = true;
      // }
    },
    add(type, data, title) {
      if (this.$refs.ruleFormA !== undefined)
        this.$refs.ruleFormA.resetFields();

      this.dx = "";
      this.dialogFormVisible = true;
      this.title = title;
      this.addType = type;
      this.isLook = true;
      this.yz = false;
      console.log(data);
      if (data != "") {
        this.upEdit = true;
        // 编辑
        this.ruleFormA.title = data.title;
        this.currentTitle = data.title;

        this.ruleFormA.comment = data.comment;
        this.ruleFormA.seninfo = data.seninfo;
        this.seninfo_log = data.seninfo_log;
        // this.content = data.seninfo
        if (data.seninfo === false) {
          this.ruleFormA.seninfo = "";
        } else {
          this.ruleFormA.seninfo = data.seninfo;
        }
        this.id = data._id;
        this.upData = [];
        // 宿主机
        if (data._sys_fid) {
          this.fileList = data._sys_fid;
        } else {
          this.fileList = [];
        }
        this.MkFun(data.modulardata);

        this.shValue = data.trytime;
        this.syValue = data.businesstime;
        this.fwValue = data.servicetime;
        this.ruleFormA.vpnid = data.vpnid;
      } else {
        this.upEdit = false;

        // 重新调取模块接口
        this.MkFun();
        this.ruleFormA.vpnid = "";
        this.shValue = "";
        this.fwValue = "";
        this.syValue = "";
        this.fileList = [];
        this.dataSource = [];
        //   this.dataSourceHost = [];
        this.id = "";
        this.ruleFormA.title = "";

        this.ruleFormA.comment = "";
        this.ruleFormA.seninfo = "";
        this.upData = [];
      }
    },
    //   typeFun(val) {
    //     console.log(val);
    //     if (val == "虚拟机") {
    //       this.hostdevD = false;
    //     } else {
    //       this.hostdevD = true;
    //       this.ruleFormA.hostdev = "";
    //     }
    //   },
    del(id, title) {
      let data = {
        op: "del",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.company, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message({
              message: title + response.data.msg,
              type: "success",
            });

            _this.init(_this.currentPage4, _this.limitVal);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
            _this.$message.info({
              message: response.data.msg,
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    quan() {
      if (this.qb == "全屏视图") {
        // this.qb = '还原视图'
        this.$emit("quan", false);
      } else {
        // this.qb = '全屏视图'
        this.$emit("quan", true);
      }
    },
    dataSet() {
      this.$refs.ruleFormA.validate((valid) => {
        if (valid) {
          let currentName = [];

          if (
            this.addType == "edit" &&
            this.currentTitle.trim() == this.ruleFormA.title.trim()
          ) {
            // 名称没变就不提交字段
            currentName = [];
          } else {
            currentName = this.ruleFormA.title.trim();
          }
          // if (this.code == "") {
          //   this.$message.info("请输入验证码！");
          //   return;
          // }

          // if (this.paaslist == "") {
          //   this.$message.info("请选择平台！");
          //   return;
          // }
          // if (this.uesrNum === "") {
          //   this.$message.info("请选择使用人数！");
          //   return;
          // }
          // if (this.version === "") {
          //   this.$message.info("请选择版本！");
          //   return;
          // }
          // if (this.reason == "") {
          //   this.$message.info("请输入申请原因！");
          //   return;
          // }
          console.log('this.ruleFormA.comment',this.ruleFormA.seninfo.length)
          if(this.ruleFormA.seninfo.length>5000){
        this.$message.info('敏感信息：内容超出最大字数5000限制，当前字符长度为'+this.ruleFormA.seninfo.length+',将无法保存！');
        return;
      }
          this.dialogMK = false;

          let data = {
            op: this.addType,
            title: currentName,
            comment: this.ruleFormA.comment,
            _id: this.id,
            seninfo: this.ruleFormA.seninfo,
            modulardata: this.MkData,
            businesstime: this.syValue,
            trytime: this.shValue,
            servicetime: this.fwValue,
            vpnid: this.ruleFormA.vpnid,
            code: this.code,
            reason: this.reason,
            paas: this.paaslist,
            number: this.uesrNum,
            version: this.version,
          };
          let _this = this;
          this.$ajax
            .post(this.company, _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$message({
                  message: response.data.msg,
                  type: "success",
                });
                _this.dialogFormVisible = false;
                if (_this.addType == "add") {
                  _this.currentPage4 = 1;
                  _this.init(1, _this.limitVal);
                } else {
                  _this.init(_this.currentPage4, _this.limitVal);
                }

                // _this.hostdevFun();
              } else if (response.data.value == "sessionerror") {
                _this.$message.error(response.data.msg);
                setTimeout(() => {
                  _this.$router.push({ path: "/" });
                }, 2500);
              } else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
        }
      });
    },
    currentP(size) {
      console.log(size);
      this.currentPage4 = size;
      this.init(size, this.limitVal);
    },
    preFun(size) {
      this.init(size, this.limitVal);
    },
    nextFun(size) {
      this.init(size, this.limitVal);
    },
  },
};
</script>
  <style lang="scss" scoped>
.el-input__inner {
  border: none;
}
.about {
  padding: 10px;
}
.qiehuan {
  min-height: 40px;
  overflow: hidden;
  .qh {
    float: right;
    margin-left: 10px;
  }
  .add {
    float: right;
    margin-bottom: 10px;
  }
  .sou {
    float: left;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
  }
}
</style>
  <style>
  .el-message-box{
    width:70% !important;
  }
.el-popover .ql-container {
  height: 80%;
}
.el-upload-list {
  display: none;
}
.el-upload {
  text-align: left !important;
}
.el-upload {
  width: 100%;
}
.about .el-form-item__label {
  /* color: #d4d4d4 !important; */
}
.el-dialog {
  margin-top: 3vh !important;
  width: 60% !important;
}
.el-select {
  width: 100%;
}
.userL .el-form-item {
  width: 100%;
  display: inline-block;
}

.el-textarea__inner {
  resize: none !important;
}
.min .el-textarea__inner {
  min-height: 150px !important;
}

thead tr th {
  height: 40px;

  /* background-color: #e3e3e3 !important; */
  /* color: #5b5b5b; */
  font-weight: bold;
}
.el-pagination {
  float: right;
  margin-top: 10px;
}
.el-table .el-table__cell {
  padding: 2px 0 !important;
}
.el-table .el-table__cell {
  margin: 0 !important;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 98% !important;
  }
  .el-form-item {
    width: 100%;
  }
}
</style>
  