<template>
  <div class="about">
    <!-- <div class="qiehuan">
        <el-button type="warning" size="small" class="qh">切换大屏展示</el-button>
      </div> -->

    <div class="qiehuan">
      <!-- <el-button type="warning" size="small" class="qh">切换大屏展示</el-button> -->

      <el-tooltip
        class="item"
        effect="dark"
        :content="qb"
        placement="top-start"
      >
        <el-button
          type="warning"
          size="mini"
          class="add"
          icon="el-icon-monitor"
          style="margin-left: 10px"
          @click="quan"
        ></el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :content="czText"
        placement="top-start"
      >
        <el-button
          type="danger"
          size="mini"
          class="add"
          plain
          @click="czFun"
          icon="el-icon-setting"
        ></el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        content="发布任务"
        placement="top-start"
      >
        <el-button
          type="success"
          size="mini"
          class="add"
          icon="el-icon-circle-plus-outline"
          @click="add('add', '', '发布任务')"
        ></el-button>
      </el-tooltip>

      <p class="sou">
        <el-checkbox
          v-model="mycreate"
          style="float: left; margin-right: 10px; padding-top: 6px"
          @change="init(1, limitVal)"
          >我发布的</el-checkbox
        >

        <el-select
          v-model="stateTop"
          placeholder="请选择状态"
          @change="locationFunI"
          size="small"
          style="width: 150px; float: left; margin-right: 5px"
          class="addFont-color"
        >
          <el-option label="请选择状态" value=""></el-option>
          <el-option label="计划" value="计划"></el-option>
          <el-option label="执行中" value="执行中"></el-option>
          <el-option label="完成" value="完成"></el-option>
          <el-option label="延迟" value="延迟"></el-option>
          <el-option label="取消" value="取消"></el-option>
        </el-select>
        <el-select
          v-model="stateDj"
          placeholder="请选择等级"
          @change="locationFunD"
          size="small"
          style="width: 150px; float: left; margin-right: 5px"
          class="addFont-color"
        >
          <el-option label="请选择等级" value=""></el-option>
          <el-option label="低" value="低"></el-option>
                <el-option label="中" value="中"></el-option>
                <el-option label="高" value="高"></el-option>
        </el-select>
        <el-input
          v-model="input"
          placeholder="请输入任务"
          style="float: left; margin-right: 2px; width: 150px"
          size="small"
          @change="init(1)"
        ></el-input>

        <el-tooltip
          class="item"
          effect="dark"
          content="搜索"
          placement="top-start"
        >
          <el-button
            type="primary"
            size="mini"
            @click="init(1)"
            icon="el-icon-search"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="重置"
          placement="top-start"
        >
          <el-button
            type="danger"
            size="mini"
            @click="resFun"
            icon="el-icon-refresh-left"
          ></el-button>
        </el-tooltip>
      </p>
    </div>
    <div style="text-align: left; color: red">
      温馨提示：双击列表每行的数据可进入查看、编辑
    </div>

    <el-table
      :data="tableData"
      border
      style="width: 100%"
      ref="table"
      v-loading="loading"
      element-loading-text="安装中"
      element-loading-spinner="el-icon-loading"
      @row-dblclick="rowdbFun"
    >
      <el-table-column type="index" :index="indexFun" width="50" label="序号" fixed="left">
      </el-table-column>
      <el-table-column prop="title" label="标题">
        <!-- <template slot-scope="scope">
          <el-popover trigger="hover" placement="right">
            <p v-if="scope.row.comment == ''">暂无</p>
            <p v-else v-html="scope.row.comment"></p>
            <div slot="reference" class="name-wrapper">
              {{ scope.row.title }}
            </div>
          </el-popover>
        </template> -->
      </el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <!-- <el-table-column prop="state" label="状态"> </el-table-column> -->

      <el-table-column prop="level" label="等级"> 
        <template slot-scope="scope">
          <span v-if="scope.row.level =='高'" style="color:red">{{scope.row.level}}</span>
          <span v-else-if="scope.row.level =='中'" style="color:rgb(255 152 0)">{{scope.row.level}}</span>
          <span v-else>{{scope.row.level}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="managerName" label="执行人">
        <template slot-scope="scope">
          <span v-for="(item, index) in scope.row.managerName" :key="index">
            {{ item }}
            {{ index == scope.row.managerName.length - 1 ? "" : "、" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="_sys_creatorName" label="创建人">
      </el-table-column>
      <el-table-column prop="_sys_createtime" label="创建时间">
      </el-table-column>
      <el-table-column prop="finishtime" label="结束时间"> </el-table-column>
      <!-- <el-table-column prop="comment" label="备注"> </el-table-column> -->
      <!-- <el-table-column prop="seninfo" label="敏感信息"> </el-table-column> -->
      <el-table-column fixed="right" label="操作" width="80" v-if="czL">
        <template slot-scope="scope">
          <i
            class="el-icon-edit"
            @click="add('edit', scope.row, '编辑')"
            style="cursor: pointer; color: #409eff"
          ></i>

          <el-popconfirm
            title="确定已完成任务？"
            @confirm="del(scope.row._id, scope.row.title)"
            v-if="scope.row.state != '完成'"
          >
            <i
              class="el-icon-wind-power"
              slot="reference"
              style="margin-left: 15px; cursor: pointer; color: green"
            ></i>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="currentP"
      :current-page="currentPage4"
      :page-sizes="[15, 20, 25, 30]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!---通知人-->
    <el-dialog title="选择通知人" :visible.sync="dialogTZR">
      <div style="margin-bottom: 20px; overflow: hidden">
        <!-- <el-checkbox-group v-model="checkboxGroup3" size="small">
      <el-checkbox-button :label="item._id"  v-for="(item, index) in fzAry" :key="index">{{ item.name }}</el-checkbox-button>

      </el-checkbox-group> -->
        <el-checkbox-group v-model="checkboxGroup3" size="small">
          <el-checkbox
            :label="item._id"
            border
            size="small"
            v-for="(item, index) in fzAry"
            :key="index"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>

        <!-- <el-checkbox v-model="checked4" label="备选项2" border size="medium"></el-checkbox> -->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTZR = false" size="small">取 消</el-button>
        <el-button type="primary" @click="dataTZR()" size="small"
          >确定并发送提醒短信</el-button
        >
      </div>
    </el-dialog>
    <!---编辑/添加-->
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :show-close="false"
      width="90%"
    >
      <div style="display: flex; justify-content: space-between">
        <div style="flex: 2">
          <el-form
            :model="ruleFormA"
            :rules="rulesA"
            ref="ruleFormA"
            label-width="100px"
            class="demo-ruleForm"
            style="border-right: 2px solid #000; padding-right: 10px"
          >
            <el-form-item label="任务类型" prop="type">
              <el-select v-model="ruleFormA.type" placeholder="" @change="typeFun">
                <!-- <el-option
              :label="item.name"
              :value="item._id"
              v-for="(item, index) in fzAry"
              :key="index"
            ></el-option> -->
                <el-option label="普通任务" value="普通任务"></el-option>
                <el-option label="虚拟机申请" value="虚拟机申请"></el-option>
                <!-- <el-option label="虚拟机" value="虚拟机"></el-option>
            <el-option label="物理机" value="物理机"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="标题" prop="title">
              <el-input v-model="ruleFormA.title" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="执行人" prop="executor">
              <el-select
                v-model="ruleFormA.executor"
                placeholder=""
                multiple
                filterable
              >
                <el-option
                  :label="item.name"
                  :value="item._id"
                  v-for="(item, index) in fzAry"
                  :key="index"
                ></el-option>
                <!-- <el-option label="虚拟机" value="虚拟机"></el-option>
            <el-option label="物理机" value="物理机"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="state">
              <el-select v-model="ruleFormA.state" placeholder="" @change="stateFun">
                <el-option label="计划" value="计划"></el-option>
                <el-option label="执行中" value="执行中"></el-option>
                <el-option label="完成" value="完成"></el-option>
                <el-option label="延迟" value="延迟"></el-option>
                <el-option label="取消" value="取消"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="等级" prop="level">
              <el-select v-model="ruleFormA.level" placeholder="">
                <el-option label="低" value="低"></el-option>
                <el-option label="中" value="中"></el-option>
                <el-option label="高" value="高"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="stime">
              <el-date-picker
                type="date"
                placeholder="选择开始时间"
                value-format="yyyy-MM-dd"
                v-model="ruleFormA.stime"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="etime">
              <el-date-picker
                type="date"
                placeholder="选择结束时间"
                value-format="yyyy-MM-dd"
                v-model="ruleFormA.etime"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="参与者">
              <el-select
                v-model="ruleFormA.cc"
                placeholder=""
                multiple
                filterable
              >
                <el-option
                  :label="item.name"
                  :value="item._id"
                  v-for="(item, index) in fzAry"
                  :key="index"
                ></el-option>
                <!-- <el-option label="虚拟机" value="虚拟机"></el-option>
            <el-option label="物理机" value="物理机"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item
              label="设备信息"
              style="width: 100%"
              v-show="ruleFormA.type == '虚拟机申请'"
            >
              <el-table :data="dataSourceHost" border style="width: 100%">
                <el-table-column prop="date" width="180">
                  <!-- <el-table-column label="bi" prop="bi" align="center"> -->
   <!-- 不使用slot 用#代替 -->
                  <template #header>
                    <span>设备名称 <span style="color: red" v-show="xx">*</span></span>  
                  </template>

                  <!-- <template #header>
                  <span>设备名称 {{xx}} <span style="color: red" v-show="xx">*</span></span>  
                  </template> -->
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typeName"></el-input>
                  </template>
                </el-table-column>

                <el-table-column prop="name" width="120">
                  <template #header>
                    主机名 <span style="color: red" v-show="xx">*</span>
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typeZ"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address">
                  <template #header>
                    ip <span style="color: red" v-show="xx">*</span>
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typeIp"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address">
                  <template #header>
                    管理员密码 <span style="color: red" v-show="xx">*</span>
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typePass"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="">
                  <template #header>
                    用途 <span style="color: red" v-show="xxB">*</span>
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typeComm"></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item
              label="设备配置"
              style="width: 100%"
              v-show="ruleFormA.type == '虚拟机申请'"
            >
              <el-table :data="dataSourceBtoom" border style="width: 100%">
                <el-table-column prop="date" label="" width="180">
                  <template #header>
                    cpu <span style="color: red" v-show="xxB">*</span>
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typeP"></el-input>
                  </template>
                </el-table-column>

                <el-table-column prop="name" label="" width="100">
                  <template #header>
                    内存 <span style="color: red" v-show="xxB">*</span>
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typeN"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="">
                  <template #header>
                    存储 <span style="color: red" v-show="xxB">*</span>
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typeC"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="gpu">
                  <template #header>
                    gpu <span style="color: red" v-show="xxB">*</span>
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typeG"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="操作系统版本">
                  <template #header>
                    操作系统版本 <span style="color: red" v-show="xxB">*</span>
                  </template>
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.typeB"></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="标签" style="width: 100%" class="tags">
              <template>
                <el-button
                  type="primary"
                  size="small"
                  style="float: left; margin-bottom: 5px"
                  @click="setTagFun"
                  >设置标签</el-button
                >
                <div
                  style="
                    width: 100%;
                    border: 1px solid #626262;
                    overflow: hidden;
                  "
                >
                  <div style="float: left; padding: 5px">
                    <div v-if="tagsCurrent.length > 0">
                      当前选中：<el-tag
                        v-for="tag in tagsCurrent"
                        :key="tag.title"
                        :style="`color:${tag.color}`"
                      >
                        {{ tag.title }}
                      </el-tag>
                    </div>
                    <div v-else>
                      当前选中标签：<span style="color: red">暂无</span>
                    </div>
                  </div>
                </div>
              </template>
            </el-form-item>
            <el-form-item label="备注" prop="comment" style="width: 100%">
              <!-- <el-input
              v-model="ruleFormA.comment"
              placeholder="请输入备注"
              type="textarea"
              class="addStyle"
            ></el-input> -->
              <!-- <Editormd
            :content="ruleFormA.comment"
            @content="contentFun"
          ></Editormd> -->
          
              <wangEditor
                style="text-align: left"
                :content="ruleFormA.comment"
                @changeData='contentFun'
                :dialogFormVisible="dialogFormVisible"
            
              ></wangEditor>
            </el-form-item>

            <!-- <el-form-item label="敏感信息" prop="seninfo">
         anjing-regPass		
anjing-remPhone	17888843257	
anjing-tokenName	安静111	
anjing-nameNew	黄莹莹	
anjing-applist	["设备管理","服务管理","单位管理","任务管理","咨询管理","交换机设备管理"]
              <Editormd :content="ruleFormA.seninfo" @content="contentFun"></Editormd>
       
        
          </el-form-item> -->
            <!-- <el-form-item
          label="敏感信息"
          prop="seninfo"
          v-if="title == '添加单位'"
          style="width: 100%"
          class="min"
        >
        
          <Editormd :content="ruleFormA.seninfo" @content="contentFun" ></Editormd>
        </el-form-item> -->
            <!-- <el-form-item
          label="敏感信息"
          v-if="title == '编辑'"
          style="width: 100%"
          class="min"
        >
          <template>
            <div>
              <el-button
                style="float: left"
                @click="
                  look(
                    ruleFormA.seninfo === false && ruleFormA.seninfo != ''
                      ? false
                      : ruleFormA.seninfo
                  )
                "
                size="small"
                type="warning"
                v-show="isLook && !yz"
                >查看</el-button
              >
              <el-dialog
                :append-to-body="true"
                title="提示"
                :visible.sync="lookValue"
                width="30%"
              >
                <span>需注册手机号接收验证码，并输入验证正确。是否查看？</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="lookValue = false">取 消</el-button>
                  <el-button type="primary" @click="lookFun">查 看</el-button>
                </span>
              </el-dialog>
              <el-input
                v-show="!isLook && !yz"
                v-model="dx"
                style="width: 160px; float: left"
                size="small"
                placeholder="请输入短信验证码"
                class="addStyle"
              ></el-input>
              <el-button
                style="float: left; margin-top: 3px; margin-left: 10px"
                @click="yanz"
                size="small"
                type="warning"
                v-show="!isLook && !yz"
                >验证</el-button
              >

              <Editormd :content="ruleFormA.seninfo" @content="contentFun"      v-if="yz"></Editormd>
            </div>
          </template>
        </el-form-item> -->
          </el-form>
          <div slot="footer" class="dialog-footer">
            <!-- <el-button @click="cgxFun" v-if="xj" type="danger"
          >存入草稿箱</el-button
        > -->
            <el-button @click="cancelFun">取 消</el-button>
            <el-button type="primary" @click="dataSet(title)">确 定</el-button>
          </div>
          <div></div>
        </div>
        <div
          style="flex: 1; margin-left: 10px; height: 83vh"
          class="elAdd"
          v-if="addType != 'add'"
        >
          <el-tabs
            v-model="activeName"
            @tab-click="handleClick"
            style="height: 100%"
            class="rightNum"
          >
            <el-tab-pane label="评论" name="first"
              ><common :rwId="rwId" :tabId="tabId"></common
            ></el-tab-pane>

            <el-tab-pane label="动态" name="second">
              <ul class="log" v-if="logList.length > 0">
                <li v-for="(item, index) in logList" :key="index">
                  <span
                    ><i class="el-icon-date" style="margin-right: 10px"></i
                    ><span style="display: inline-block; padding-right: 20px">{{
                      item.opt
                    }}</span
                    ><span>{{ item.user }}</span></span
                  ><span>{{ item.time }}</span>
                </li>
              </ul>
              <el-empty
                :description="emptyText"
                v-if="logList.length === 0"
              ></el-empty>
            </el-tab-pane>
            <el-tab-pane label="附件" name="third">
              <div>
                <el-upload
                  style="float: left"
                  class="upload-demo"
                  action="/"
                  ref="upload"
                  :on-success="onSuccess"
                  multiple
                  :before-upload="beforeUpload"
                  :on-exceed="handleExceed"
                  :on-error="onError"
                  :file-list="fileList"
                  :show-file-list="showFList"
                  :http-request="checkedFile"
                  @close="handleClose(index, tag)"
                >
                  <el-button size="small" type="primary" v-if="upEdit"
                    >上传附件</el-button
                  >
                </el-upload>
                <el-button
                  size="small"
                  type="primary"
                  v-if="!upEdit"
                  @click="upFun"
                  style="float: left"
                  >上传附件</el-button
                >
              </div>

              <div class="tabAll">
                <!-- <el-tooltip
              class="item"
              effect="dark"
              :content="tag.name"
              placement="bottom"
             
            > -->
                <el-tag
                  v-for="(tag, index) in fileList"
                  :key="index"
                  style="
                    margin-right: 10px;
                    float: left;
                    margin-top: 10px;
                    cursor: pointer;
                  "
                  :disable-transitions="false"
                  @close="handleClose(index, tag)"
                  closable
                  @click="downloadFile(tag)"
                  ><i class="el-icon-paperclip"></i
                  ><span class="tagtext">{{ tag.name }}</span></el-tag
                >
                <!-- </el-tooltip> -->
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-dialog>
    <!---标签设置弹框-->
    <el-dialog
      title="设置标签"
      append-to-body
      :visible.sync="dialogVisibleTag"
      width="30%"
    >
      <div>
        <el-tabs :tab-position="tabPosition" style="height: 400px">
          <el-tab-pane
            :label="item.title"
            v-for="(item, index) in dynamicTags"
            :key="index"
          >
            <el-tag
              @click="tabFun(itemii._id, itemii.title, itemii.color, indexii)"
              v-for="(itemii, indexii) in item.labels"
              :key="indexii"
              :style="`color:${itemii.color};cursor: pointer;margin:5px;margin:5px;`"
              >{{ itemii.title }}</el-tag
            >
          </el-tab-pane>
          <!-- <el-tab-pane label="配置管理">配置管理</el-tab-pane>
          <el-tab-pane label="角色管理">角色管理</el-tab-pane>
          <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane> -->
        </el-tabs>
        <div style="margin-top: 10px">
          <el-popover
            placement="top-start"
            title=""
            width="600"
            trigger="click"
          >
            <div>
              <div>
               <div style="    text-align: left;margin-bottom:15px;">
                <span style='display:inline-block;width:20%;'>择标签类型名称：</span>
                <el-select v-model="typeVaD" placeholder="请选择标签类型名称" size="mini" style="width:50%">
                  <el-option label="设备标签" value="设备标签"> </el-option>
                  <el-option label="服务标签" value="服务标签"> </el-option>
                  <el-option label="所在系统" value="所在系统"> </el-option>
                </el-select>
               </div> 
               <div style="    text-align: left;margin-bottom:15px; "><span style='display:inline-block;width:20%;'>标签名称：</span> <el-input style="width:50%" v-model="conVaD" placeholder="请输入标签名称" size="mini"></el-input></div>
               
                <el-button type="success" size="mini" style="float:right" @click="addBqFun">添加</el-button>
              </div>
            
            </div>
            <el-button
              slot="reference"
              type="success"
              icon="el-icon-circle-plus-outline"
              size="mini"
              >自定义标签</el-button
            >
          </el-popover>
        </div>
      </div>
      <div
        v-if="tagsCurrent.length > 0"
        style="border-top: 1px solid #000; margin-top: 10px; padding-top: 10px"
      >
        当前选中：<el-tag
          v-for="(tag, index) in tagsCurrent"
          :key="index"
          closable
          :style="`color:${tag.color}`"
          @close="closeTagFun(tag.title, index)"
        >
          {{ tag.title }}
        </el-tag>
      </div>
      <div
        v-else
        style="border-top: 1px solid #000; margin-top: 10px; padding-top: 10px"
      >
        当前选中标签：<span style="color: red">暂无</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTag = false">关闭</el-button>
        <!-- <el-button type="primary" @click="dialogVisibleTag = false"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
  </div>
</template>
  <script>
import inputIp from "../components/inputIp.vue";

// import Editormd from "./edmitormd.vue";
import common from "./comment.vue";

import wangEditor from "./wangEditor";
//   // 引入markdown文件示例
// import demo from '../assets/demo.md'
export default {
  components: {
    inputIp,
    // Editormd,
    common,
    wangEditor,
  },
  data() {
    return {
      typeVaD: undefined,
      conVaD:'',
      xx:false,
      aa:'没有',
      xxB:true,
      emptyText: "暂无动态",
      logList: [],
      tabId: "",
      rwId: "",
      activeName: "first",
      xj: false,
      cgxValue: false,
      showFList: false,
      upEdit: false,
      czText: "显示操作栏",
      czL: false,
      maxHeight: document.body.offsetHeight - 190,
      //上传后的文件列表
      fileList: [],
      // 允许的文件类型
      fileType: [
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "txt",
        "png",
        "jpg",
        "bmp",
        "jpeg",
        "html",
        "zip",
      ],
      // 运行上传文件大小，单位 M
      fileSize: 50,
      // 附件数量限制
      fileLimit: 5,
      //请求头
      headers: { "Content-Type": "multipart/form-data" },
      isClear: false, //设置为true的时候，这个可以用this.wangEditorDetail=''来替代
      wangEditorDetail: "",
      tabPosition: "left",
      dialogVisibleTag: false,
      tagsCurrent: [],
      content: "",
      currentTitle: "",
      // currentHostname:'',
      lookValue: false,
      loading: false,
      limitVal: 15,
      companyV: "",
      locationV: "",
      dx: "",
      // qb:"全屏视图",
      isLook: true,
      idC: "",
      yz: false,
      hostdevD: true,
      currentPage4: 1,

      company: [],
      locationAry: [],
      dialogFormVisible: false,
      dialogTZR: false,
      checkboxGroup3: [],
      input: "",
      id: "",
      dataSource: [],

      dataSourceHost: [
        
        { typeName: "", typeZ: "", typeIp: "", typePass: "", typeComm: "" },
      ],
      dataSourceBtoom: [
        { typeP: "", typeN: "", typeC: "", typeG: "", typeB: "" },
      ],
      tableData: [],
      widthS: document.body.clientWidth,
      // addStyle:'',
      ruleFormA: {
        type: "普通任务",
        title: "",
        comment: "",
        seninfo: "",
        state: "计划",
        executor: [],
        level: "",
        stime: "",
        etime: "",
        cc: undefined,
      },
      total: null,
      showpsw: null,
      rulesA: {
        title: [{ required: true, message: "请输入设备名称", trigger: "blur" }],
        state: [{ required: true, message: "请选择状态", trigger: "blur" }],

        level: [{ required: true, message: "请选择等级", trigger: "change" }],
        executor: [
          { required: true, message: "请选择执行人", trigger: "change" },
        ],
      },
      title: "编辑",
      fzAry: [],
      addType: "",
      hostdevAry: [],
      companyAry: [],
      // isLook: true,
      stateTop: "计划",
      stateDj:'',
      titleTop: "",
      mycreate: false,
      // yz: false,
      dynamicTags: [],
      multiUploadSize: 5000000, // 大于这个大小的文件使用分块上传(后端可以支持断点续传)
      requestCancelQueue: [], // 请求方法队列（调用取消上传）
      eachSize: 5000000, // 每块文件大小
      currentChunk: 0,
    };
  },
  props: {
    qb: String,
    companyAryD: [],
  },
  watch: {
    companyAryD: {
      handler(val) {
        console.log(val);

        this.init(1, this.limitVal);
      },
    },
  },
  mounted() {
    // this.get_content()
    //   setInterval(()=>{
    //     this.init(this.currentPage4,this.limitVal);
    //   },60000)
    this.init(1, this.limitVal);
    this.labelFun();

    console.log(this.widthS);
    //   this.hostdevFun();
    //   this.locationFun();
    //   this.companyFun();
    //   this.fzInit();
  },
  methods: {
      //添加标签
      addBqFun(){
      if(!this.typeVaD){
        this.$message.info('请选择标签类型名称！');
        return;

      }
      if(!this.conVaD){
        this.$message.info('请输入标签名称!');
        return;

      }
  
      let data = {
        op: "addlabel",
        type: this.typeVaD,
        title:this.conVaD
        // name: name,
      };
      let _this = this;
      this.$ajax
        .post(this.label, _this.Qs.stringify(data))

        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message.success(response.data.msg);
            _this.labelFun();
            // _this.init(_this.currentPage4, _this.limitVal);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // handleClick(tab, event) {
    //     console.log(event);

    //   },
    //   initPl(){
    //   let data = {
    //     op: "replylist",
    //     _id:this.rwId
    //   };
    //   let _this = this;
    //   this.$ajax
    //     .post(this.task, _this.Qs.stringify(data))
    //     .then(function (response) {
    //       console.log(response);
    //       if (response.data.value == "ok") {
    //         // _this.dynamicTags = response.data.data;
    //       } else {
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    // 下载
    downloadFile(tag) {
      console.log(tag);
      if (tag.url === undefined) {
        this.$message("请稍后再试！");
      }
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = tag.url;
      document.body.appendChild(link);
      link.click();
    },
    upFun() {
      this.$message("新建暂不支持上传！");
    },
    //上传文件之前
    beforeUpload(file) {
      //upEdit 为false是新建
      // if(!this.upEdit){
      //   this.$message("新建暂不支持上传！");
      //   return;

      // }
      if (file.type != "" || file.type != null || file.type != undefined) {
        //截取文件的后缀，判断文件类型
        const FileExt = file.name.replace(/.+\./, "").toLowerCase();
        //计算文件的大小
        const isLt5M = file.size / 1024 / 1024 < 50; //这里做文件大小限制
        //如果大于50M
        if (!isLt5M) {
          this.$message("上传文件大小不能超过 50MB!");
          return false;
        }
        //如果文件类型不在允许上传的范围内
        // if (this.fileType.includes(FileExt)) {
        //   return true;
        // } else {
        //   this.$message.error("上传文件格式不正确!");
        //   return false;
        // }
      }
    },
    async checkedFile(options) {
      // if(!this.upEdit){
      //   this.$message("新建暂不支持上传！");
      //   return;

      // }
      debugger;
      console.log(options);
      const { multiUploadSize, getSize, splitUpload, singleUpload } = this;
      const { file, onProgress, onSuccess, onError } = options;
      // if (file.size > maxSize) {
      //     return this.$message({
      //         message: `您选择的文件大于${getSize(maxSize)}`,
      //         type: 'error'
      //     })
      // }
      // if (
      //   !(
      //     file.name.indexOf("actor") == 0 ||
      //     file.name.indexOf("activity") == 0 ||
      //     file.name.indexOf("statement") == 0
      //   )
      // ) {
      //   // if( this.fileList.length>0){
      //   //     this.fileList.shift()
      //   // }
      //   return this.$message({
      //     message: `请您使用教学平台导出的zip文件，请按原文件名称上传，不要修改文件名称`,
      //     type: "error",
      //   });
      // }
      const uploadFunc =
        file.size > multiUploadSize ? splitUpload : singleUpload;
      console.log(uploadFunc);
      try {
        await uploadFunc(file, onProgress);
        // this.$message({
        //     message: '上传成功',
        //     type: 'success'
        // })
        onSuccess();
      } catch (e) {
        console.error(e);
        this.$message({
          message: e.message,
          type: "error",
        });
        onError();
      }
      const prom = new Promise((resolve, reject) => {});
      prom.abort = () => {};
      return prom;
    },
    postFile(param, onProgress) {
      let _this = this;
      const formData = new FormData();
      for (let p in param) {
        formData.append(p, param[p]);
      }
      const { requestCancelQueue } = this;
      const config = {
        cancelToken: new this.$ajax.CancelToken(function executor(cancel) {
          if (requestCancelQueue[param.uid]) {
            requestCancelQueue[param.uid]();
            delete requestCancelQueue[param.uid];
          }
          requestCancelQueue[param.uid] = cancel;
          console.log(requestCancelQueue);
        }),
        onUploadProgress: (e) => {
          if (param.chunked) {
            e.percent = Number(
              (
                ((param.blob_num * (param.total_blob_num - 1) + e.loaded) /
                  param.eachSize) *
                100
              ).toFixed(2)
            );
          } else {
            e.percent = Number(((e.loaded / e.total) * 100).toFixed(2));
          }
          onProgress(e);
        },
      };

      return this.$ajax.post(this.task, formData, config).then((rs) => {
        // rs.data
        console.log(rs.data);
        // _this.fileList = rs.data.data.data;
        if (rs.data && rs.data.msg&&rs.data.msg.indexOf("部分") != -1) {
          _this.currentChunk = rs.data;
          _this.$message.success(rs.data.msg);

          console.log(_this.fileList.length);
          _this.showFList = true;
          console.log("0000000000000000");
          console.log(document.getElementsByClassName("el-upload-list__item"));
          if (
            document.getElementsByClassName("el-upload-list__item").length != 0
          ) {
            debugger;
            document.getElementsByClassName("el-upload-list")[0].style.display =
              "block";

            for (let i = 0; i < _this.fileList.length; i++) {
              document.getElementsByClassName("el-upload-list__item")[
                i
              ].style.display = "none";
              console.log(
                document.getElementsByClassName("el-upload-list__item")[i]
              );
            }
          }
        } else {
          _this.fileList = rs.data.data.data;
          this.showFList = false;
        }
      });
    },
    removeFile(file) {
      console.log(this.requestCancelQueue);
      console.log(file);
      this.requestCancelQueue[file.uid]();
      delete this.requestCancelQueue[file.uid];
      return true;
    },
    // 格式化文件大小显示文字
    getSize(size) {
      return size > 1024
        ? size / 1024 > 1024
          ? size / (1024 * 1024) > 1024
            ? (size / (1024 * 1024 * 1024)).toFixed(2) + "GB"
            : (size / (1024 * 1024)).toFixed(2) + "MB"
          : (size / 1024).toFixed(2) + "KB"
        : size.toFixed(2) + "B";
    },
    // 单文件直接上传
    singleUpload(file, onProgress) {
      return this.postFile(
        // FormDatas.append("file", item.file);
        // FormDatas.append("op", "uploadfile");
        // FormDatas.append("_id", this.id);
        {
          op: "uploadfile",
          file,
          blob_num: 1,
          file_name: file.name,
          total_blob_num: 1,
          _id: this.id,
          // homepage: sessionStorage.getItem("homepage"),
        },
        onProgress
      );
    },
    // 大文件分块上传
    splitUpload(file, onProgress) {
      debugger;
      return new Promise(async (resolve, reject) => {
        try {
          const { eachSize } = this;
          const chunks = Math.ceil(file.size / eachSize);
          const fileChunks = await this.splitFile(file, eachSize, chunks);
          console.log(fileChunks);
          this.currentChunk = 0;
          for (let i = 0; i < fileChunks.length; i++) {
            // 服务端检测已经上传到第currentChunk块了，那就直接跳到第currentChunk块，实现断点续传
            console.log(this.currentChunk, i);

            if (
              Number(this.currentChunk) === i ||
              Number(this.currentChunk.index) === i
            ) {
              // if (Number(currentChunk.index) >= 1) {
              //     this.$message({
              //         message: currentChunk.msg,
              //         type: 'success'
              //     })
              // }
              // 每块上传完后则返回需要提交的下一块的index
              await this.postFile(
                {
                  op: "uploadfile",
                  chunked: true,
                  blob_num: i + 1,
                  total_blob_num: chunks,
                  _id: this.id,
                  eachSize,
                  file_name: file.name,
                  // fullSize: file.size,
                  // blob_num: file.uid,
                  file: fileChunks[i],
                  // homepage: sessionStorage.getItem("homepage"),
                },
                onProgress
              );
              console.log(this.currentChunk);
            }
          }
          // const isValidate = await this.validateFile({
          //     total_blob_num: fileChunks.length,
          //     file_name: file.name,
          //     // fullSize: file.size,
          //     uid: file.uid

          //     //total_blob_num  文件块总数
          //     // blob_num  当前块编号
          //     // file_name 文件名
          // })
          // if (!isValidate) {
          //     throw new Error('文件校验异常')
          // }
          resolve();
        } catch (e) {
          reject(e);
        }
      });
    },
    // 文件分块,利用Array.prototype.slice方法
    splitFile(file, eachSize, chunks) {
      debugger;
      return new Promise((resolve, reject) => {
        try {
          setTimeout(() => {
            const fileChunk = [];
            for (let chunk = 0; chunks > 0; chunks--) {
              fileChunk.push(file.slice(chunk, chunk + eachSize));
              chunk += eachSize;
            }
            resolve(fileChunk);
          }, 0);
        } catch (e) {
          console.error(e);
          reject(new Error("文件切块发生错误"));
        }
      });
    },
    onSuccess(response, file, fileList) {
      this.fileList = fileList;
      console.log(fileList);
      console.log(file);
      console.log(response);

      this.requestCancelQueue = this.fileList;
      console.log(this.requestCancelQueue);

      if (
        !(
          file.name.indexOf("actor") == 0 ||
          file.name.indexOf("activity") == 0 ||
          file.name.indexOf("statement") == 0
        )
      ) {
        this.fileList.pop();
      } else if (fileList.length == 4) {
        this.fileList.shift();
      }

      console.log(response, file, fileList);
    },
    onError(response, file, fileList) {
      console.log(response);
      console.log(file);
      console.log(fileList);
      this.$message.warning(`上传失败，请重新上传！！！`);
    },
    //上传了的文件给移除的事件，由于我没有用到默认的展示，所以没有用到
    handleRemove() {},
    //这是我自定义的移除事件
    handleClose(i, tag) {
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let _this = this;
          this.$ajax({
            method: "post",
            url: _this.task,
            headers: _this.headers,
            timeout: 30000,
            data: { op: "delfile", _id: _this.id, fid: tag.fid },
          }).then((res) => {
            if (res.data.value == "ok") {
              _this.fileList.splice(i, 1); //删除上传的文件
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              // if (_this.fileList.length > 0) {
              //如果上传了附件就把校验规则给干掉
              // this.fileflag = false;
              // this.$set(this.rules.url, 0, "");
              // }
              //this.handleSuccess();
            } else {
              _this.$message.error("文件删除失败！");
            }
          });
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });

      // if (this.fileList.length == 0) {
      //如果删完了
      // this.fileflag = true; //显示url必填的标识
      // this.$set(this.rules.url, 0, {
      //   required: true,
      //   validator: this.validatorUrl,
      //   trigger: "blur",
      // }); //然后动态的添加本地方法的校验规则
      // }
    },
    //超出文件个数的回调
    handleExceed() {
      this.$message({
        type: "warning",
        message: "超出最大上传文件数量的限制！",
      });
      return;
    },
    //上传文件的事件
    uploadFile(item) {
      this.$message("文件上传中........");
      //上传文件的需要formdata类型;所以要转
      let FormDatas = new FormData();
      FormDatas.append("file", item.file);
      FormDatas.append("op", "uploadfile");
      FormDatas.append("_id", this.id);
      let _this = this;
      this.$ajax({
        method: "post",
        url: _this.task,
        headers: _this.headers,
        timeout: 30000,
        data: FormDatas,
      }).then((res) => {
        if (res.data.value == "ok") {
          debugger;
          _this.fileList = res.data.data; //成功过后手动将文件添加到展示列表里
          _this.requestCancelQueue = _this.fileList;
          console.log(_this.fileList);
          // let i = _this.fileList.indexOf(item.file);

          // _this.fileList[i].fid = res.data.data[i].fid; //id也添加进去，最后整个大表单提交的时候需要的
          if (_this.fileList.length > 0) {
            //如果上传了附件就把校验规则给干掉
            // this.fileflag = false;
            // this.$set(this.rules.url, 0, "");
          }
          //this.handleSuccess();
        } else {
          _this.$message.error("文件上传失败！");
        }
      });
    },
    //上传成功后的回调
    handleSuccess() {},
    // 删除标签
    closeTagFun(title, index) {
      this.tagsCurrent.splice(index, 1);
      // debugger
      // delete  this.tagsCurrent[index]
      console.log(this.tagsCurrent);
    },
    // 选中标签
    tabFun(id, title, color, index) {
      // let curTag = this.tagsCurrent.indexOf(title);
      if (this.tagsCurrent.length > 0) {
        debugger;
        let temp = true;
        for (let i = 0; i < this.tagsCurrent.length; i++) {
          if (this.tagsCurrent[i].title == title) {
            temp = false;
            break;
          } else {
            temp = true;
          }
        }
        if (temp) {
          this.tagsCurrent.push({ title: title, id: id, color: color });
        } else {
          this.$message("已选中");
        }
      } else {
        this.tagsCurrent.push({ title: title, id: id, color: color });
      }
      console.log(this.tagsCurrent);
    },
    // 设置标签
    setTagFun() {
      this.dialogVisibleTag = true;
    },
    // 获取标签
    labelFun() {
      let data = {
        op: "labellist",
        labeltype: "",
      };
      let _this = this;
      this.$ajax
        .post(this.label, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.dynamicTags = response.data.data;
            console.log('AAAAAAAAAAAAAAAA3',_this.dynamicTags)
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    rowdbFun(row) {
      this.add("edit", row, "编辑");
    },
    czFun() {
      if (this.czText == "显示操作栏") {
        this.czText = "关闭操作栏";
        this.czL = true;
        //在执行完跳转页码的数据请求后
        this.$nextTick(() => {
          this.$refs.table.bodyWrapper.scrollTop = 0;
        });
      } else {
        this.czText = "显示操作栏";
        this.czL = false;
      }
    },
    fzInit(Ary) {
      let data = {
        op: "userlist",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.fzAry = response.data.data;
            let currentData = response.data.data.data;

            console.log(_this.tableData);
            debugger;
            for (let i = 0; i < Ary.length; i++) {
              Ary[i].managerName = [];
              for (let y = 0; y < _this.fzAry.length; y++) {
                if (Ary[i].executor) {
                  for (let ii = 0; ii < Ary[i].executor.length; ii++) {
                    if (Ary[i].executor[ii] == _this.fzAry[y]._id) {
                      Ary[i].managerName.push(_this.fzAry[y].name);

                      console.log(Ary);
                    }
                  }
                }
                if (Ary[i]._sys_creator) {
                  if (Ary[i]._sys_creator == _this.fzAry[y]._id) {
                    Ary[i]._sys_creatorName = _this.fzAry[y].name;
                    console.log(Ary);
                  }
                }
              }
            }
            console.log(Ary);
            _this.tableData = Ary;
            console.log(_this.tableData);
          } else {
            // _this.tableData = Ary;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loglistFun() {
      let data = {
        op: "loglist",
        _id: this.id,
        // code: this.dx,

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.task, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.logList = response.data.data;
            // console.log(response.data.data);
            // _this.ruleFormA.seninfo = response.data.data;
            // _this.isLook = false;
            // _this.yz = true;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            // _this.isLook = true;
            // _this.yz = false;
            // console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 提交验证码
    yanz() {
      // console.log(id)
      // 调取接口
      // 校验通过
      if (this.dx.trim() == "") {
        this.$message({
          message: "请输入验证码！",
          type: "warning",
        });
        return;
      }
      let data = {
        op: "getseninfo",
        _id: this.id,
        code: this.dx,

        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.ruleFormA.seninfo = response.data.data;
            _this.isLook = false;
            _this.yz = true;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            // _this.isLook = true;
            _this.yz = false;
            console.log(_this.isLook);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log(this.yz);
    },
    cgxFun() {
      debugger;
      // this.dialogFormVisible = false;
      // this.$message.success("已存入草稿箱");

      console.log(this.tagsCurrent);

      //  this.tagsCurrent = [];
      //  this.dataSource = [];
      //  this.ruleFormA.title = "";
      //  this.ruleFormA.state = "计划";
      //  this.ruleFormA.comment = "";
      //  this.ruleFormA.executor = [];
    },
    lookFun() {
      // 校验通过
      let data = {
        op: "getvfycode",
        // title: this.input,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.lookValue = false;
          if (response.data.value == "ok") {
            // console.log(response.data.data);
            _this.$message({
              message: response.data.msg,
              type: "success",
            });
            _this.isLook = false;
          } else if (response.data.value == "fail") {
            _this.isLook = false;
          } else {
            _this.$message({
              message: response.data.msg,
              type: "warning",
            });
            _this.isLook = true;
            // console.log( _this.isLook)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    contentFun(val) {
      this.ruleFormA.comment = val;
    },
    indexFun(index) {
      return this.limitVal * (this.currentPage4 - 1) + index + 1;
    },

    change() {
      console.log(7);
      this.$forceUpdate();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limitVal = val;
      this.init(1, val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    companyFunI() {
      this.init(1, this.limitVal);
    },
    locationFunI() {
      this.init(1, this.limitVal);
    },
    locationFunD() {
      this.init(1, this.limitVal);
    },
    resFun() {
      this.input = "";
      this.stateTop = "计划";
      this.mycreate = false;
      this.currentPage4 = 1;
      this.limitVal = 15;
      this.init(this.currentPage4, this.limitVal);
    },
    init(page, limit = this.limitVal) {
      console.log(typeof this.mycreate);
      console.log(typeof JSON.parse(this.mycreate));
      this.currentPage4 = page;
      let data = {
        op: "search",
        title: this.input,
        state: this.stateTop,
        mycreate: this.mycreate == false ? 0 : 1,
        level:this.stateDj,
        page: page,
        limit: limit,
      };
      let _this = this;
      this.$ajax
        .post(this.task, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.total = response.data.data.recordcount;
            //   _this.tableData = response.data.data.data;
            _this.fzInit(response.data.data.data);
            if (_this.currentPage4 != 1 && _this.tableData.length == 0) {
              // 解决删除最后一页的只有一条数据的情况，那就从上一页开始查询
              _this.currentPage4--;
              _this.init(_this.currentPage4, _this.limitVal);
            }
            // _this.fzInit(response.data.data.data);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    handleClick(row) {
      console.log(row);
      if (row.index == "0") {
        // 评论
        this.rwId = this.id;
        this.tabId = row.index;
        // this.initPl()
        //  this.emit('init',this.rwId)
      } else if (row.index == "1") {
        this.loglistFun();
      }
    },
    // 状态
    stateFun(val){
      console.log(val)
      debugger
      if(val == '完成'){
        this.xx =true;
        this.aa='完成'

        this.xxB =true;

      }
      else {
        this.aa='非完成'
        this.xx =false;
        this.xxB =true;
      }
      console.log( this.xx)
    },
    cancelFun() {
      debugger;
      this.dialogFormVisible = false;

      if (this.xj) {
        this.$message.info("已存入草稿箱！");

        this.cgxValue = true;
        window.localStorage.setItem("anjing-rw-level", this.ruleFormA.level);
        window.localStorage.setItem("anjing-rw-fileList", this.fileList);
        window.localStorage.setItem(
          "anjing-rw-tagsCurrent",
          JSON.stringify(this.tagsCurrent)
        );
        window.localStorage.setItem(
          "anjing-rw-dataSource",
          JSON.stringify(this.dataSource)
        );
        window.localStorage.setItem("anjing-rw-title", this.ruleFormA.title);
        window.localStorage.setItem("anjing-rw-state", this.ruleFormA.state);
        window.localStorage.setItem(
          "anjing-rw-comment",
          this.ruleFormA.comment
        );
        window.localStorage.setItem("anjing-rw-stime", this.ruleFormA.stime);
        window.localStorage.setItem("anjing-rw-etime", this.ruleFormA.etime);
        window.localStorage.setItem("anjing-rw-cc", this.ruleFormA.cc);

        window.localStorage.setItem(
          "anjing-rw-executor",
          JSON.stringify(this.ruleFormA.executor)
        );
        this.activeName = "first";
      }
    },

    dataTZR() {
      let _this = this;
      let data = {
        op: "replymsg",
        title: this.ruleFormA.title,
        executor: this.checkboxGroup3.length == 0 ? "" : this.checkboxGroup3,
      };
      this.$ajax
        .post(this.task, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.dialogTZR = false;

            _this.$message({
              message: response.data.msg,
              type: "success",
            });
          } else {
            // this.dialogTZR = false;
            _this.$message({
              message: response.data.msg,
              type: "info",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onDelete(index, rows) {
      rows.splice(index, 1);
      // this.dis = false;
    },
    //   changeFun(id) {
    //     console.log(id);
    //     this.valueS = id;
    //   },
    look() {
      let data = {
        op: "checkseninfo",
        // title: this.input,
        _id: this.id,
      };
      let _this = this;
      this.$ajax
        .post(this.device, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          _this.lookValue = false;
          if (response.data.value == "ok") {
            _this.isLook = false;
            _this.ruleFormA.seninfo = response.data.data;
            _this.yz = true;
          } else {
            _this.lookValue = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // if (seninfo === false) {
      //   this.lookValue = true;
      // } else {
      //   this.ruleFormA.seninfo = seninfo;
      //   this.isLook = false;
      //   this.yz = true;
      // }
    },
    add(type, data, title) {
      if (this.$refs.ruleFormA !== undefined) {
        this.$refs.ruleFormA.resetFields();
        console.log(9);
      }
      this.dialogFormVisible = true;

      this.dx = "";

      this.title = title;
      this.addType = type;
      this.isLook = true;
      this.yz = false;
      console.log(data);

      if (data != "") {
        this.xj = false;

        this.upEdit = true;
        // 编辑
        this.ruleFormA.title = data.title;
        this.currentTitle = data.title;
        this.ruleFormA.state = data.state;
        this.ruleFormA.comment = data.comment;
        this.ruleFormA.level = data.level;
        this.ruleFormA.executor = data.executor;
        this.ruleFormA.stime = data.stime;
        this.ruleFormA.etime = data.etime;
        this.ruleFormA.cc = data.cc;
        // if(!data.type){
        //   this.ruleFormA.type ='普通任务'
        // }else {
          this.ruleFormA.type = data.type || '普通任务';
        // }
  

        //   this.ruleFormA.seninfo = data.seninfo;
        // this.content = data.seninfo
        //   if (data.seninfo === false) {
        //   this.ruleFormA.seninfo = false;
        // } else {
        //   this.ruleFormA.seninfo = data.seninfo;
        // }
        this.id = data._id;
        if (data._sys_label) {
          this.tagsCurrent = data._sys_label;
        } else {
          this.tagsCurrent = [];
        }
        if (data._sys_fid) {
          this.fileList = data._sys_fid;
        } else {
          this.fileList = [];
        }
        this.loglistFun();
        this.tabId = "0";
        this.rwId = data._id;
     
       // devinfo:'',//设备信息
        //devconf
        if(this.ruleFormA.type =='虚拟机申请'){
          // let devinfoLength= data.devinfo.length;
          
          this.dataSourceHost= [{ typeName: data.devinfo[0], typeZ: data.devinfo[1], typeIp: data.devinfo[2], typePass: data.devinfo[3], typeComm:data.devinfo[4]}];
          this.dataSourceBtoom= [{ typeP: data.devconf[0], typeN: data.devconf[1], typeC: data.devconf[2], typeG: data.devconf[3], typeB: data.devconf[4]}];
          if(data.state=='完成' ){
        this.xx =true;
        this.xxB =true;

      }
      else {
        this.xx =false;
        this.xxB =true;
      }
        }
        
  

      
    
        // 宿主机
      } else {
        debugger;
        this.xj = true;
        this.id = "";
        this.upEdit = false;

        if (
          window.localStorage.getItem("anjing-rw-title") === null ||
          window.localStorage.getItem("anjing-rw-title") == ""
        ) {
          this.fileList = [];
          this.tagsCurrent = [];
          this.dataSource = [];
          this.ruleFormA.title = "";
          this.ruleFormA.state = "计划";
          this.ruleFormA.type = '普通任务';
   
          // let devinfoLength= data.devinfo.length;
          
          this.dataSourceHost= [{ typeName:'', typeZ: '', typeIp: '', typePass: '', typeComm:''}];
          this.dataSourceBtoom= [{ typeP: '', typeN: '', typeC: '', typeG: '', typeB: ''}];
          this.xx =false;
        this.xxB =true;
          this.ruleFormA.comment = "";
          this.ruleFormA.executor = [];
          this.ruleFormA.level = "";
          this.ruleFormA.stime = "";
          this.ruleFormA.etime = "";
          this.ruleFormA.cc = undefined;
        } else {
          this.ruleFormA.level = window.localStorage.getItem("anjing-rw-level");
          this.fileList = [];
          this.tagsCurrent = JSON.parse(
            window.localStorage.getItem("anjing-rw-tagsCurrent")
          );
          this.dataSource = JSON.parse(
            window.localStorage.getItem("anjing-rw-dataSource")
          );
          this.ruleFormA.title = window.localStorage.getItem("anjing-rw-title");
          this.ruleFormA.state = window.localStorage.getItem("anjing-rw-state");
          this.ruleFormA.stime = window.localStorage.getItem("anjing-rw-stime");
          this.ruleFormA.etime = window.localStorage.getItem("anjing-rw-etime");
          this.ruleFormA.cc = window.localStorage.getItem("anjing-rw-cc");
          this.ruleFormA.comment =
            window.localStorage.getItem("anjing-rw-comment");
          this.ruleFormA.executor = JSON.parse(
            window.localStorage.getItem("anjing-rw-executor")
          );
        }
      }
      // console.log(this.$refs.ruleFormA)
    },

      typeFun(val) {
        console.log(val);
        if(val == '虚拟机申请' && this.ruleFormA.state=='完成'){
        this.xx =true;
        this.xxB =true;

      }
      else {
        this.xx =false;
        this.xxB =true;
      }
      },
    del(id, title) {
      let data = {
        op: "finish",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.task, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message({
              message: title + response.data.msg,
              type: "success",
            });

            _this.init(_this.currentPage4, _this.limitVal);
          } else if (response.data.value == "sessionerror") {
            _this.$message.error(response.data.msg);
            setTimeout(() => {
              _this.$router.push({ path: "/" });
            }, 2500);
          } else {
            _this.$message.info({
              message: response.data.msg,
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    quan() {
      if (this.qb == "全屏视图") {
        // this.qb = '还原视图'
        this.$emit("quan", false);
      } else {
        // this.qb = '全屏视图'
        this.$emit("quan", true);
      }
    },
    dataSet() {
      this.$refs.ruleFormA.validate((valid) => {
        if (valid) {
          // let currentName = [];

          // if(this.addType == 'edit' && (this.currentTitle).trim() == (this.ruleFormA.title).trim()){// 名称没变就不提交字段
          //   currentName = [];
          // }

          // else {
          //   currentName = (this.ruleFormA.title).trim();
          // }
          if (this.ruleFormA.stime < this.ruleFormA.etime) {
          }
          if (this.ruleFormA.stime != "" && this.ruleFormA.etime != "") {
            let sT = new Date(this.ruleFormA.stime);
            let sE = new Date(this.ruleFormA.etime);
            if (sT > sE) {
              return this.$message.info("结束日期要晚于或者等于开始日期！");
            }
          }
      
           
          if(this.ruleFormA.state=='完成'){
            if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceHost[0].typeName==''){
            this.$message.info('任务类型为【虚拟机申请】时，设备信息中的【设备名称】为必填');
            return;
          }
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceHost[0].typeZ=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，设备信息中的【主机名】为必填');
            return;
          }
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceHost[0].typeIp=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，设备信息中的【ip】为必填');
            return;
          }
       
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceHost[0].typePass=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，设备信息中的【管理员密码】为必填');
            return;
          } 

            if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceHost[0].typeComm=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【用途】为必填');
            return;
          }
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeP=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【CPU】为必填');
            return;
          }
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeN=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【内存】为必填');
            return;
          }
       
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeC=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【存储】为必填');
            return;
          } 
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeG=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【gpu】为必填');
            return;
          } 
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeB=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【操作系统版本】为必填');
            return;
          }
          
          }
          else {
            if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceHost[0].typeComm=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【用途】为必填');
            return;
          }
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeP=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【CPU】为必填');
            return;
          }
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeN=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【内存】为必填');
            return;
          }
       
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeC=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【存储】为必填');
            return;
          } 
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeG=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【gpu】为必填');
            return;
          } 
          if(this.ruleFormA.type =='虚拟机申请' &&this.dataSourceBtoom[0].typeB=='' ){
            this.$message.info('任务类型为【虚拟机申请】时，状态为【完成】时，设备信息中的【操作系统版本】为必填');
            return;
          }
          }
        

          let data = {
            op: this.addType,
            title: this.ruleFormA.title,
            type:this.ruleFormA.type,
        
                        _id: this.id,
            //   seninfo:this.ruleFormA.seninfo,
            //   title
            state: this.ruleFormA.state,
            level: this.ruleFormA.level,
            executor: this.ruleFormA.executor,
            comment: this.ruleFormA.comment,
            stime: this.ruleFormA.stime,
            etime: this.ruleFormA.etime,
            cc: this.ruleFormA.cc,
            _sys_label: this.tagsCurrent.length == 0 ? "" : this.tagsCurrent,
          };
         if(this.ruleFormA.type =='虚拟机申请'){
            data.devinfo=[this.dataSourceHost[0].typeName,this.dataSourceHost[0].typeZ,this.dataSourceHost[0].typeIp,this.dataSourceHost[0].typePass,this.dataSourceHost[0].typeComm];//设备信息
            data.devconf=[this.dataSourceBtoom[0].typeP,this.dataSourceBtoom[0].typeN,this.dataSourceBtoom[0].typeC,this.dataSourceBtoom[0].typeG,this.dataSourceBtoom[0].typeB];//配置信息 

          };
          console.log(this.dataSourceHost)
          

       
          let _this = this;
          this.$ajax
            .post(this.task, _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.cgxValue = false;
                window.localStorage.setItem("anjing-rw-title", "");
                _this.$message({
                  message: response.data.msg,
                  type: "success",
                });
                // 弹出通知人
                // _this.dialogTZR = true;
                _this.dialogFormVisible = false;
                if (_this.addType == "add") {
                  _this.currentPage4 = 1;
                  _this.init(1, _this.limitVal);
                } else {
                  _this.init(_this.currentPage4, _this.limitVal);
                }

                // _this.hostdevFun();
              } else if (response.data.value == "sessionerror") {
                _this.$message.error(response.data.msg);
                setTimeout(() => {
                  _this.$router.push({ path: "/" });
                }, 2500);
              } else {
                _this.$message.error(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
        }
      });
    },
    currentP(size) {
      console.log(size);
      this.currentPage4 = size;
      this.init(size, this.limitVal);
    },
    preFun(size) {
      this.init(size, this.limitVal);
    },
    nextFun(size) {
      this.init(size, this.limitVal);
    },
  },
};
</script>
  <style lang="scss" scoped>
.tabAll {
  margin-top: 0px;
  width: 100%;
  overflow: auto;
  float: left;
  /* float:left */
}
.el-checkbox.is-bordered.el-checkbox--small {
  width: 115px;
  float: left;
  margin: 10px;
}
.el-input__inner {
  border: none;
}
.about {
  padding: 10px;
}
.qiehuan {
  min-height: 40px;
  overflow: hidden;
  .qh {
    float: right;
    margin-left: 10px;
  }
  .add {
    float: right;
    margin-bottom: 10px;
  }
  .sou {
    float: left;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
  }
}
</style>
  <style>
.elAdd .el-tabs__header.is-top {
  padding: 0 10px;
}
.elAdd .el-tabs__nav-scroll {
  margin-top: 0;
}
.el-upload-list {
  display: none;
}
.el-upload {
  text-align: left !important;
}
.about .el-form-item__label {
  /* color: #d4d4d4 !important; */
}
.about .el-dialog {
  margin-top: 3vh !important;
  width: 90% !important;
}
.el-select {
  width: 100%;
}
.el-form-item {
  width: 50%;
  display: inline-block;
}

.el-textarea__inner {
  resize: none !important;
}
.min .el-textarea__inner {
  min-height: 150px !important;
}

thead tr th {
  height: 40px;

  /* background-color: #e3e3e3 !important; */
  /* color: #5b5b5b; */
  font-weight: bold;
}
.el-pagination {
  float: right;
  margin-top: 10px;
}
.el-table .el-table__cell {
  padding: 2px 0 !important;
}
.el-table .el-table__cell {
  margin: 0 !important;
}
/* .addFont-color .el-input__inner {
  color: #fff100;
} */
.log li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.rightNum .el-tabs__content {
  max-height: 88%;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 98% !important;
  }
  .el-form-item {
    width: 100%;
  }
}
</style>
  